import { shopUrlPropertyName } from "@smartrr/shared/constants";
import { IShopifyData } from "@smartrr/shared/entities/ShopifyData";

import { SmartrrVendorSubReducer } from "@vendor-app/app/_state/typedVendorRedux";
import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";
import { getShopUrlFromGlobals } from "@vendor-app/utils/shopify/getShopUrlFromGlobals";

export interface IShopifyStoreDataReducerState {
  isLoading: boolean;
  [shopUrlPropertyName]?: string;
  shopifyData?: IShopifyData;
}

const initialState: IShopifyStoreDataReducerState = {
  [shopUrlPropertyName]: getShopUrlFromGlobals(),
  isLoading: true,
};

export const shopifyStoreDataReducer: SmartrrVendorSubReducer<IShopifyStoreDataReducerState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "LOADED_VENDOR_PORTAL":
    case "LOADED_USER": {
      const matchingOrgRole = action.payload.user?.vendorOrgRoles?.find(
        orgRole => orgRole.organization.id === action.payload.activeOrganizationId
      );

      if (!matchingOrgRole?.organization.shopifyStoreData) {
        return {
          ...state,
          [shopUrlPropertyName]: undefined,
          isLoading: false,
        };
      }

      return {
        ...state,
        [shopUrlPropertyName]: matchingOrgRole.organization.myShopifyDomain,
        shopifyData: matchingOrgRole.organization.shopifyStoreData,
      };
    }
    case "SELECT_ACTIVE_ORGANIZATION": {
      const orgId = action.payload.orgId;
      const selectedOrgRole = action.payload.user.vendorOrgRoles?.find(
        orgRole => orgRole.organization.id === orgId
      );
      const shopifyData = selectedOrgRole?.organization.shopifyStoreData;

      return {
        ...state,
        [shopUrlPropertyName]: shopifyData?.myShopifyDomain,
        shopifyData,
      };
    }
    default: {
      return state;
    }
  }
};

export function useMyShopifyDomainSelector() {
  return useSmartrrVendorSelector(state => state.shopifyStoreData[shopUrlPropertyName]);
}

export function useActiveOrganizationCurrencySelector() {
  return useSmartrrVendorSelector(state => state.shopifyStoreData.shopifyData?.currency);
}
