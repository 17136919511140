import { uniq, uniqBy } from "lodash";
import { useMemo } from "react";

import { IPurchasable } from "../../entities/Purchasable";
import { IPurchasableVariant } from "../../entities/PurchasableVariant";
import { ScheduledDeliveryGroupWithPurchSt } from "../../entities/PurchaseState/scheduleUtils";
import { shopifyGidToNumber } from "../ensureShopifyGid";

export interface MappedPurchasableVariant {
  [id: string]: IPurchasable;
}

export const getVariantToPurchasableMap = (purchasables: IPurchasable[]): MappedPurchasableVariant => {
  return (purchasables || []).filter(Boolean).reduce((acc, purchasable) => {
    for (const variant of purchasable.vnts ?? []) {
      acc[variant.id] = purchasable;
    }
    return acc;
  }, {} as { [id: string]: IPurchasable });
};

/**
 * Generates a Map between Smartrr ID of a Variant and the Purchasable it belongs to.
 * @param purchasables List of purchasables to map
 * @returns A Map between the Smartrr Variant ID and the Purchasable
 */
export function useVariantToPurchasableMap(purchasables: IPurchasable[]): MappedPurchasableVariant {
  return useMemo(() => getVariantToPurchasableMap(purchasables), [purchasables]);
}

export interface MappedIdToVariant {
  [id: string]: IPurchasableVariant;
}
export const getVariantIdToVariantMap = (purchasables: IPurchasable[]): MappedIdToVariant => {
  return (purchasables || []).filter(Boolean).reduce<MappedIdToVariant>((acc, purchasable) => {
    for (const variant of purchasable.vnts ?? []) {
      if (variant.isActiveInShopify && !variant.isDraftOrArchived) {
        acc[variant.id] = variant;
      }
    }
    return acc;
  }, {});
};

/**
 * Generates a Map between Smartrr ID of a Variant and the Variant so we dont need to pass in the entire variant everytime.
 * @param purchasables List of purchasables to map
 * @returns A Map between the Smartrr Variant ID and the Variant
 */
export function useVariantIdToVariantMap(purchasables: IPurchasable[]): MappedIdToVariant {
  return useMemo(() => getVariantIdToVariantMap(purchasables), [purchasables]);
}

export const getVariantShopifyIDToPurchasableMap = (purchasables: IPurchasable[]): MappedPurchasableVariant => {
  return (purchasables || []).filter(Boolean).reduce((acc, purchasable) => {
    for (const variant of purchasable.vnts ?? []) {
      if (variant.shopifyId) {
        const shopifyId = shopifyGidToNumber(variant.shopifyId);
        acc[shopifyId] = purchasable;
      }
    }
    return acc;
  }, {} as { [id: string]: IPurchasable });
};

/**
 * Generates a Map between numerical portion of the Shopify Variant ID of a Variant
 * and the Purchasable it belongs to. (Ex id: gid://shopify/ProductVariant/46450615189806)
 * @param purchasables List of purchasables to map
 * @returns A Map between the Shopify Variant ID and the Purchasable
 */
export function useVariantShopifyIDToPurchasableMap(purchasables: IPurchasable[]): MappedPurchasableVariant {
  return useMemo(() => getVariantShopifyIDToPurchasableMap(purchasables), [purchasables]);
}

export function useDeliveryGroupPurchasables(
  purchasables: IPurchasable[],
  scheduledDeliveryGroup: ScheduledDeliveryGroupWithPurchSt[]
) {
  const variantToPurchasableMap = useVariantToPurchasableMap(purchasables);

  return useMemo(
    () =>
      uniqBy(
        (scheduledDeliveryGroup || [])
          .reduce(
            (acc: IPurchasableVariant[], g) =>
              acc.concat(g.purchaseState.stLineItems.filter(item => !!item.vnt).map(item => item.vnt!)),
            []
          )
          .map(v => variantToPurchasableMap[v.id])
          .filter(Boolean),
        ({ id }) => id
      ),
    [variantToPurchasableMap, scheduledDeliveryGroup]
  );
}

export function getPurchasableFromPurchasableMap(
  variantToPurchasablesMap: { [id: string]: IPurchasable },
  variant?: IPurchasableVariant
) {
  return variant ? variantToPurchasablesMap[variant.id] : null;
}

export function getNameFromPurchasableMap(
  variant: IPurchasableVariant,
  variantToPurchasablesMap: { [id: string]: IPurchasable }
) {
  return getPurchasableFromPurchasableMap(variantToPurchasablesMap, variant)?.purchasableName;
}

export function getVariantImageFromPurchasableMap(
  variantToPurchasablesMap: { [id: string]: IPurchasable },
  variant?: IPurchasableVariant
) {
  const variantImage = variant?.purchasableVariantImages?.filter(Boolean)[0];

  if (variantImage) {
    return variantImage;
  }

  return getPurchasableImage(getPurchasableFromPurchasableMap(variantToPurchasablesMap, variant));
}

export function getPurchasableImage(purchasable?: IPurchasable | null): string | undefined {
  return (
    (purchasable?.purchasableImages ?? []).find(Boolean) ||
    (purchasable?.purchasableSmallImages ?? []).find(Boolean)
  );
}

export function getVariantSmallImageFromPurchasableMap(
  variantToPurchasablesMap: { [id: string]: IPurchasable },
  variant?: IPurchasableVariant
) {
  const variantImage = variant?.purchasableVariantSmallImages?.filter(Boolean)[0];

  if (variantImage) {
    return variantImage;
  }

  const purchasableImage = getPurchasableSmallImage(
    getPurchasableFromPurchasableMap(variantToPurchasablesMap, variant)
  );

  return purchasableImage || getVariantImageFromPurchasableMap(variantToPurchasablesMap, variant);
}

export function getPurchasableSmallImage(product?: IPurchasable | null): string | undefined {
  return (product?.purchasableSmallImages ?? []).find(Boolean) || getPurchasableImage(product);
}

export function getAllProductAndVariantImages(
  product: IPurchasable,
  variantToPurchasableMap: {
    [id: string]: IPurchasable;
  }
): string[] {
  const productImgSrc = getPurchasableImage(product);
  const arr: string[] = [];

  if (productImgSrc) {
    arr.push(productImgSrc);
  }

  if (product.vnts?.length) {
    for (const vnt of product.vnts) {
      const imgSrc = getVariantImageFromPurchasableMap(variantToPurchasableMap, vnt);
      imgSrc && arr.push(imgSrc);
    }
  }
  return uniq(arr);
}
