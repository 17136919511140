import { IPhone } from "@smartrr/shared/entities/Phone";

import { SmartrrVendorSubReducer } from "@vendor-app/app/_state/typedVendorRedux";

export interface IPhoneReducerState {
  isInitializing: boolean;
  isLoading: boolean;
  phone?: IPhone;
}

const initialState: IPhoneReducerState = {
  isInitializing: true,
  isLoading: true,
};

export const phoneReducer: SmartrrVendorSubReducer<IPhoneReducerState> = (
  state = initialState,
  action
): IPhoneReducerState => {
  switch (action.type) {
    case "LOADING_PHONE": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "ERROR_LOADING_PHONE": {
      return {
        ...state,
        isLoading: false,
      };
    }

    case "LOADED_PHONE": {
      return {
        ...state,
        isLoading: false,
        isInitializing: false,
        phone: action.payload.phone
          ? {
              ...state.phone,
              ...action.payload.phone,
            }
          : undefined,
      };
    }

    default: {
      return state;
    }
  }
};
