import { AsyncProviderConfig, useFlags } from "launchdarkly-react-client-sdk";
import type { KeysToCamelCase } from "../types";

export const SPECIAL_REFERRALS_NOT_ACTIVE_FLAG_VALUE = "not-active";

export const LaunchDarklyFlags = {
  "loyalty-analytics": false,
  "migration-emails-enabled": false,
  "new-prepaid": false,
  "notification-email-refactor": false,
  "prepaid-sequential": false,
  "special-referrals": SPECIAL_REFERRALS_NOT_ACTIVE_FLAG_VALUE,
  "subscription-management-section-v-2": false,
  "subscription-programs-improvement": false,
  "tiered-loyalty": false,
  "shop-webhooks-processing-with-temporal": false,
  "unbundle-recover": false,
  "upcoming-order-table-rework": false,
};

export type CamelCaseSmartrrFlags = KeysToCamelCase<typeof LaunchDarklyFlags>;
export const useSmartrrFlags = useFlags<CamelCaseSmartrrFlags>;

// clientSideID needs to be evaluated during runtime - use `ldGetClientSideId()`
export const LaunchDarklyOptions: Omit<AsyncProviderConfig, "clientSideID"> = {
  options: {
    streaming: true,
    bootstrap: "localStorage",
  },
  flags: LaunchDarklyFlags,
  reactOptions: {
    useCamelCaseFlagKeys: true,
    sendEventsOnFlagRead: true,
  },
};
