import { ICustomerPortalTheme } from "@smartrr/shared/entities/CustomerPortalTheme";
import { IAsset } from "@smartrr/shared/shopifyRest/asset";
import { defaultCustomerPortalThemeConfig } from "@smartrr/shared/themes/defaultThemeConfig";
import { merge } from "lodash";
import { SmartrrVendorSubReducer } from "@vendor-app/app/_state/typedVendorRedux";

export interface ICustomerPortalThemeReducerState {
  isInitializing: boolean;
  isLoading: boolean;
  hasSavedCustomerPortalTheme: boolean;
  customerPortalTheme: ICustomerPortalTheme;
  availableFontAssets: IAsset[];
}

const initialState: ICustomerPortalThemeReducerState = {
  isInitializing: true,
  isLoading: true,
  hasSavedCustomerPortalTheme: true,
  availableFontAssets: [],
  customerPortalTheme: {},
};

export const customerPortalThemeReducer: SmartrrVendorSubReducer<ICustomerPortalThemeReducerState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "SELECT_ACTIVE_ORGANIZATION": {
      return initialState;
    }

    case "LOADING_CUSTOMER_PORTAL_THEME": {
      return {
        ...state,
        isLoading: true,
        isInitializing: true,
      };
    }

    case "UPDATING_CUSTOMER_PORTAL_THEME": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "ERROR_LOADING_CUSTOMER_PORTAL_THEME":
    case "ERROR_UPDATING_CUSTOMER_PORTAL_THEME": {
      return {
        ...state,
        isInitializing: false,
        isLoading: false,
      };
    }

    case "LOADED_CUSTOMER_PORTAL_THEME":
    case "UPDATED_CUSTOMER_PORTAL_THEME": {
      return {
        ...state,
        isInitializing: false,
        isLoading: false,
        hasSavedCustomerPortalTheme: !!action.payload.customerPortalTheme,
        customerPortalTheme: merge(
          {},
          defaultCustomerPortalThemeConfig,
          state.customerPortalTheme,
          action.payload.customerPortalTheme
        ),
      };
    }

    default: {
      return state;
    }
  }
};
