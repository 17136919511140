import { useCallback } from "react";
import { MapStateToProps, connect, shallowEqual, useDispatch, useSelector, useStore } from "react-redux";

import { ISmartrrState } from "./reducers";
import { GeneralSmartrrDispatch, SmartrrAction, SmartrrActionActionType } from "./typedVendorRedux";

export function useSmartrrVendorStore() {
  return useStore<ISmartrrState, SmartrrAction<SmartrrActionActionType>>();
}

export function useSmartrrVendorDispatch() {
  return useDispatch<GeneralSmartrrDispatch>();
}

export function useSmartrrVendorSelector<TSelected>(
  selector: (state: ISmartrrState) => TSelected,
  equalityFn: (left: TSelected, right: TSelected) => boolean = shallowEqual // we default to shallow equal even though redux does not now
) {
  return useSelector<ISmartrrState, TSelected>(useCallback(selector, []), equalityFn);
}

export function smartrrVendorConnect<IFromStoreProps, IOwnProps = {}>(
  mapStateToProps: MapStateToProps<IFromStoreProps, IOwnProps, ISmartrrState>
) {
  return connect<IFromStoreProps, { dispatch: GeneralSmartrrDispatch }, IOwnProps, ISmartrrState>(
    mapStateToProps,
    (dispatch: GeneralSmartrrDispatch) => ({ dispatch })
  );
}
