import { DateTime } from "luxon";
import { z } from "zod";

/**
 * A string in ISO format (ISO 8601 Extended Format):
 * YYYY-MM-DDTHH:mm:ss.sssZ
 */

// TODO: To make OpenAPI work
export type ISODateString = any;
// ^still a cancer; this was like this when i got here; i tried to fix it and didn't have time to battle all the way through

export const ISODateString = {
  /**
   * Transforms Date or DateTime into ISO 8601 format.
   * @param {DateTime | Date} luxonDateTime
   */
  toString(luxonDateTime: DateTime | Date | string): ISODateString {
    if (typeof luxonDateTime === "string") {
      return z.string().datetime().parse(luxonDateTime);
    }

    if (luxonDateTime instanceof DateTime) {
      return luxonDateTime.toISO() as unknown as ISODateString;
    }

    return luxonDateTime.toISOString() as unknown as ISODateString;
  },

  /**
   * Creates a DateTime instance from ISO 8601 string.
   * @param isoDateString
   */
  // ^but also creates it from a Date or a DateTime...?
  fromString(isoDateString: ISODateString): DateTime {
    if (isoDateString instanceof Date) {
      return DateTime.fromJSDate(isoDateString);
    }

    if (isoDateString instanceof DateTime) {
      return isoDateString.setZone("UTC");
    }

    return DateTime.fromISO(isoDateString).setZone("UTC");
  },

  earliestPossibleTime: DateTime.utc(0, 0, 0),
  earliestPossibleTimeString: DateTime.utc(0, 0, 0).toISO() as unknown as ISODateString,

  equals(base: ISODateString | Date | DateTime, other: ISODateString | Date | DateTime) {
    const baseDateTime = ISODateString.fromString(base as ISODateString)
      .toJSDate()
      .getTime();
    const otherDateTime = ISODateString.fromString(other as ISODateString)
      .toJSDate()
      .getTime();
    return baseDateTime === otherDateTime;
  },
};
