/**
 * A time component in ISO format:
 * HH:mm:ss
 */

// TODO: To Make OpenAPI work
export type ISOTimeString = any;

function pad(num: number, size: number): string {
  let s = num.toString();
  while (s.length < size) {
    s = "0" + s;
  }
  return s;
}

export const ISOTimeString = {
  /**
   * Returns time in ISOTimeString format.
   */
  toString(hh: number, mm = 0, ss = 0): ISOTimeString {
    return `${pad(hh, 2)}:${pad(mm, 2)}:${pad(ss, 2)}` as unknown as ISOTimeString;
  },

  /**
   * Creates time components from ISO 8601 string time component.
   */
  fromString(isoTimeString: ISOTimeString): [number, number, number] {
    const [hh, mm, ss = 0] = ((isoTimeString as unknown as string) ?? "00:00")
      .split(":")
      .map(s => parseInt(s, 10));
    return [hh, mm, ss];
  },
};
