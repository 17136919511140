import { IPurchasableCollection } from "@smartrr/shared/entities/PurchasableCollection";

import { SmartrrVendorSubReducer } from "@vendor-app/app/_state/typedVendorRedux";

export interface IPurchasableCollectionsReducerState {
  isLoading: boolean;
  purchasableCollections: IPurchasableCollection[];
}

const initialState: IPurchasableCollectionsReducerState = {
  isLoading: true,
  purchasableCollections: [],
};

//
//
//
// NOT USING RIGHT NOW (just pulling off of purchasables)
//
//
//

export const purchasableCollectionsReducer: SmartrrVendorSubReducer<IPurchasableCollectionsReducerState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "SELECT_ACTIVE_ORGANIZATION": {
      return initialState;
    }

    case "LOADING_PURCHASABLE_COLLECTIONS": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "ERROR_LOADING_PURCHASABLE_COLLECTIONS": {
      return {
        ...state,
        isLoading: false,
      };
    }

    case "LOADED_PURCHASABLE_COLLECTIONS": {
      return {
        ...state,
        isLoading: false,
        purchasableCollections: action.payload.purchasableCollections,
      };
    }

    default: {
      return state;
    }
  }
};
