export const copyToClipboard = async (
  text: string | undefined | null,
  successMsg: string,
  toast: (msg: string) => void
) => {
  try {
    if (!text) {
      throw new Error("Cannot copy text");
    }
    await navigator.clipboard.writeText(text);
    toast(successMsg);
  } catch (error) {
    toast("Cannot copy text");
    console.error(error);
  }
};
