import React from "react";
import styled, { css } from "styled-components";

// @ts-ignore
export { Box } from "./Box";
import { pxStringWithMin } from "../../themes";

import { filter, isNil } from "lodash";

export function prefixClassName(...suffixes: string[]): string {
  return suffixes.reduce((classes, newClass) => classes + ` smartrr-portal-${newClass.trim()}`, "").trim();
}

/**
 * Will return stringified list of classes that have a truthy value
 * Example:
 * {
 *  "class-1": check1 && check2,
 *  "class-2": true,
 *  "class-3": functionThatReturnsABoolean()
 * }
 */
interface OptionalCSSClasses {
  [name: string]: boolean;
}
export function optionalClassName(classes: OptionalCSSClasses): string {
  let classList = "";
  for (const className of Object.keys(classes)) {
    if (classes[className]) {
      classList += ` smartrr-portal-${className.trim()}`;
    }
  }
  return classList.trim();
}

export const formatStringForClassName = (title: string) => {
  /*
  Convert space ' ' to '-'
  remove any characters that are not a-z or '-'
  */
  const cleanString = title.toLowerCase().replace(" ", "-");
  return filter(cleanString, char => /[a-z\-]/.test(char)).join("");
};

export const SCHEDULED_DELIVERIES_CONTAINER_WIDTH = 600;
export const INPUT_HEIGHT = 40;
export const INPUT_LINE_HEIGHT = 20;

export enum MediaMinWidths {
  Small = 480,
  Medium = 768,
  Large = 960,
  Larger = 1200,
}

export const Button = styled.button<{ secondary?: boolean; disabled?: boolean }>`
  background: ${props => (props.secondary ? "transparent" : props.theme.button?.backgroundColor)};
  border-color: ${props => props.theme.button?.borderColor};
  border-radius: ${props => props.theme.button?.borderRadius};
  border-style: solid;
  border-width: ${props => {
    if (props.secondary) {
      return props.theme.button?.borderWidth ? pxStringWithMin(1, props.theme.button.borderWidth) : "1px";
    }
    return props.theme.button?.borderWidth;
  }};

  color: ${props =>
    props.secondary
      ? props.theme.button?.backgroundColor
      : props.theme.button?.color}; /* to get rid of shop theme settings */
  cursor: ${props => (props.disabled ? "default" : "pointer")}; /* to get rid of shop theme settings */
  font-family: ${props => props.theme.button?.fontFamily}; /* to get rid of shop theme settings */
  font-size: ${props => props.theme.button?.fontSize}; /* to get rid of shop theme settings */

  font-weight: ${props => props.theme.button?.fontWeight};
  height: unset;
  line-height: unset;
  min-height: 2.5rem;
  min-width: unset;
  outline: none;

  position: relative;

  text-decoration: none;
  transition: opacity 0.15s ease-in-out;
  user-select: none;

  white-space: nowrap;
  &:focus {
    outline: none;
  }

  &:hover {
    opacity: 0.6;
  }
`;

export interface BoxProps {
  direction?: "row" | "column";
  gap?: number | string;
  alignItems?: React.CSSProperties["alignItems"];
  justifyContent?: React.CSSProperties["justifyContent"];

  m?: number;
  mx?: number;
  my?: number;
  mb?: number;
  mt?: number;
  ml?: number;
  mr?: number;

  p?: number;
  px?: number;
  py?: number;
  pb?: number;
  pt?: number;
  pl?: number;
  pr?: number;
}

export const FlexRow = styled.div<BoxProps>`
  display: flex;

  ${props => props.alignItems && `align-items: ${props.alignItems};`}
  ${props => props.justifyContent && `justify-content: ${props.justifyContent};`}
  ${props => {
    if (isNil(props.gap)) {
      return ``;
    }

    // safari doesn't support row-gap or column-gap in flexbox
    return `
      > * + * {
        ${
          props.direction === "row"
            ? `margin-left: ${typeof props.gap === "number" ? `${props.gap}rem` : props.gap}`
            : `margin-top: ${typeof props.gap === "number" ? `${props.gap}rem` : props.gap}`
        }
      }
    `;
  }}

  ${props => props.m && `margin: ${props.m}rem;`}
  ${props => props.my && `margin-top: ${props.my}rem; margin-bottom: ${props.my}rem;`}
  ${props => props.mx && `margin-left: ${props.mx}rem; margin-right: ${props.mx}rem;`}
  ${props => props.mt && `margin-top: ${props.mt}rem;`}
  ${props => props.mb && `margin-bottom: ${props.mb}rem;`}
  ${props => props.ml && `margin-left: ${props.ml}rem;`}
  ${props => props.mr && `margin-right: ${props.mr}rem;`}

  ${props => props.p && `padding: ${props.p}rem;`}
  ${props => props.py && `padding-top: ${props.py}rem; padding-bottom: ${props.py}rem;`}
  ${props => props.px && `padding-left: ${props.px}rem; padding-right: ${props.px}rem;`}
  ${props => props.pt && `padding-top: ${props.pt}rem;`}
  ${props => props.pb && `padding-bottom: ${props.pb}rem;`}
  ${props => props.pl && `padding-left: ${props.pl}rem;`}
  ${props => props.pr && `padding-right: ${props.pr}rem;`}
`;

export const FlexColumnMixin = css`
  display: flex;
  flex-direction: column;
`;
export const FlexColumn = styled(FlexRow)`
  ${FlexColumnMixin}
`;
