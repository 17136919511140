import { IPurchaseStateWithCustomerRelationshipPaginatedResponse } from "@smartrr/shared/entities/PurchaseState";
import { updateMatchInArray } from "@smartrr/shared/utils/updateMatchInArray";

import { SmartrrVendorSubReducer } from "@vendor-app/app/_state/typedVendorRedux";

export interface IPurchaseStatesReducerState extends IPurchaseStateWithCustomerRelationshipPaginatedResponse {
  isLoading: boolean;
  isStreaming: boolean;
  customerUserId?: string;
}

const initialState: IPurchaseStatesReducerState = {
  isStreaming: false,
  isLoading: false,
  totalCount: 0,
  totalPages: 0,
  pageSize: 0,
  pageNumber: 0,
  data: [],
};

export const purchaseStatesReducer: SmartrrVendorSubReducer<IPurchaseStatesReducerState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "SELECT_ACTIVE_ORGANIZATION": {
      return initialState;
    }

    case "PAUSING_CUSTOMER_PURCHASE_STATE":
    case "ACTIVATING_CUSTOMER_PURCHASE_STATE":
    case "BULK_PAUSING_CUSTOMER_PURCHASE_STATES":
    case "BULK_SETTING_NEXTDATE_CUSTOMER_PURCHASE_STATES":
    case "BULK_UNPAUSING_CUSTOMER_PURCHASE_STATES":
    case "BULK_CANCELLING_CUSTOMER_PURCHASE_STATES":
    case "CANCELLING_CUSTOMER_PURCHASE_STATE":
    case "CREATING_CUSTOMER_PURCHASE_STATE":
    case "UPDATING_CUSTOMER_PURCHASE_STATES":
    case "LOADING_CUSTOMER_PURCHASE_STATE":
    case "EDIT_CPS_SHIPPING_ADDRESS":
    case "ADD_CPS_DISCOUNT":
    case "DELETE_CPS_DISCOUNT":
    case "ADD_CPS_LINE_ITEM":
    case "BULK_ADD_CPS_LINE_ITEM":
    case "BULK_UPDATE_CPS_LINE_ITEM":
    case "BULK_REMOVE_CPS_LINE_ITEM":
    case "UPDATE_CPS_LINE_ITEM":
    case "DELETE_CPS_LINE_ITEM":
    case "UPDATE_CPS_SELLING_PLAN":
    case "UPDATE_CPS_SHIPPING_PRICE":
    case "UPDATE_CPS_NEXT_DATE":
    case "UNSKIP_CPS_NEXT_ORDER":
    case "SWAP_PURCHASE_STATE_LINE_ITEM":
    case "SKIP_CPS_NEXT_ORDER":
    case "SEND_NOW_PURCHASE_STATE":
    case "ADD_NOTE_PURCHASE_STATE":
    case "BULK_SKIP_CPS_NEXT_ORDERS":
    case "BULK_UNSKIP_CPS_NEXT_ORDERS":
    case "LOADING_CUSTOMER_PURCHASE_STATES": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "ERROR_PAUSING_CUSTOMER_PURCHASE_STATE":
    case "ERROR_ACTIVATING_CUSTOMER_PURCHASE_STATE":
    case "ERROR_BULK_PAUSING_CUSTOMER_PURCHASE_STATES":
    case "ERROR_BULK_UNPAUSING_CUSTOMER_PURCHASE_STATES":
    case "ERROR_BULK_CANCELLING_CUSTOMER_PURCHASE_STATES":
    case "ERROR_BULK_SETTING_NEXTDATE_CUSTOMER_PURCHASE_STATES":
    case "CREATED_CUSTOMER_PURCHASE_STATE":
    case "ERROR_CREATING_CUSTOMER_PURCHASE_STATE":
    case "ERROR_UPDATING_CUSTOMER_PURCHASE_STATES":
    case "ERROR_LOADING_CUSTOMER_PURCHASE_STATE":
    case "ERROR_EDITING_CPS_SHIPPING_ADDRESS":
    case "ERROR_ADDING_CPS_DISCOUNT":
    case "ERROR_DELETING_CPS_DISCOUNT":
    case "ERROR_DELETING_CPS_LINE_ITEM":
    case "ERROR_UPDATING_CPS_LINE_ITEM":
    case "ERROR_ADDING_CPS_LINE_ITEM":
    case "ERROR_BULK_ADDING_CPS_LINE_ITEM":
    case "ERROR_BULK_UPDATING_CPS_LINE_ITEM":
    case "ERROR_BULK_REMOVING_CPS_LINE_ITEM":
    case "ERROR_UPDATING_CPS_SELLING_PLAN":
    case "ERROR_UPDATING_CPS_SHIPPING_PRICE":
    case "ERROR_UPDATING_CPS_NEXT_DATE":
    case "ERROR_UNSKIPPING_CPS_NEXT_ORDER":
    case "ERROR_SKIPPING_CPS_NEXT_ORDER":
    case "ERROR_SWAPPING_PURCHASE_STATE_LINE_ITEM":
    case "ERROR_SENDING_NOW_PURCHASE_STATE":
    case "ERROR_ADDING_NOTE_PURCHASE_STATE":
    case "ERROR_BULK_SKIPPING_CPS_NEXT_ORDERS":
    case "ERROR_BULK_UNSKIPPING_CPS_NEXT_ORDERS":
    case "ERROR_LOADING_CUSTOMER_PURCHASE_STATES": {
      return {
        ...state,
        isLoading: false,
      };
    }

    case "BULK_PAUSED_CUSTOMER_PURCHASE_STATES":
    case "BULK_UNPAUSED_CUSTOMER_PURCHASE_STATES":
    case "BULK_CANCELLED_CUSTOMER_PURCHASE_STATES":
    case "BULK_SET_NEXTDATE_CUSTOMER_PURCHASE_STATES":
    case "LOADED_CUSTOMER_PURCHASE_STATES": {
      return {
        ...state,
        isLoading: false,
        ...action.payload.customerPurchaseStates,
      };
    }

    // streaming
    case "STREAMING_RECHARGE_SUBSCRIPTION_DATA":
    case "STREAMING_SHOPIFY_SUBSCRIPTION_CONTRACT_DATA": {
      return {
        ...state,
        isStreaming: true,
      };
    }

    case "STREAMING_SHOPIFY_SUBSCRIPTION_CONTRACT_DATA_ITEM": {
      const customerPurchaseStateFromPayload = action.payload.customerPurchaseState;
      const upsertedCustomerPurchaseState = {
        // cloned and using already-existing
        ...(state.data.find(({ id }) => id === customerPurchaseStateFromPayload.id) ||
          customerPurchaseStateFromPayload),
      };

      return {
        ...state,
        data: updateMatchInArray(
          state.data,
          upsertedCustomerPurchaseState,
          cps => cps.id,
          "newValue",
          false // we only update, don't insert if not found (as orders are paginated
        ),
      };
    }

    case "ERROR_STREAMING_RECHARGE_SUBSCRIPTION_DATA":
    case "ERROR_STREAMING_SHOPIFY_SUBSCRIPTION_CONTRACT_DATA": {
      return {
        ...state,
        isStreaming: false,
      };
    }

    case "STREAMING_RECHARGE_SUBSCRIPTION_DATA_COMPLETE":
    case "STREAMING_SHOPIFY_SUBSCRIPTION_CONTRACT_DATA_COMPLETE": {
      return {
        ...state,
        isStreaming: false,
      };
    }

    case "UPDATED_CUSTOMER_PURCHASE_STATES": {
      // updatedCustomerPurchaseStateWithUsers should contain all CPSs for that user,
      // whether they updated them or not
      const { updatedCustomerPurchaseStateWithUsers } = action.payload;

      return {
        ...state,
        isLoading: false,
        data: updatedCustomerPurchaseStateWithUsers.reduce(
          (cpsStates, cps) => updateMatchInArray(cpsStates, cps, ({ id }) => id, "newValue"),
          state.data
        ),
      };
    }

    case "EDITED_CPS_SHIPPING_ADDRESS":
    case "PAUSED_CUSTOMER_PURCHASE_STATE":
    case "ACTIVATED_CUSTOMER_PURCHASE_STATE":
    case "ADDED_CPS_DISCOUNT":
    case "DELETED_CPS_DISCOUNT":
    case "ADDED_CPS_LINE_ITEM":
    case "BULK_ADDED_CPS_LINE_ITEM":
    case "BULK_UPDATED_CPS_LINE_ITEM":
    case "BULK_REMOVED_CPS_LINE_ITEM":
    case "UPDATED_CPS_LINE_ITEM":
    case "DELETED_CPS_LINE_ITEM":
    case "UPDATED_CPS_SELLING_PLAN":
    case "UPDATED_CPS_SHIPPING_PRICE":
    case "UPDATED_CPS_NEXT_DATE":
    case "SKIPPED_CPS_NEXT_ORDER":
    case "UNSKIPPED_CPS_NEXT_ORDER":
    case "SWAPPED_PURCHASE_STATE_LINE_ITEM":
    case "SENT_NOW_PURCHASE_STATE":
    case "ADDED_NOTE_PURCHASE_STATE":
    case "BULK_SKIPPED_CPS_NEXT_ORDERS":
    case "BULK_UNSKIPPED_CPS_NEXT_ORDERS":
    case "CANCELLED_CUSTOMER_PURCHASE_STATE": {
      return {
        ...state,
        isLoading: false,
        data: updateMatchInArray(state.data, action.payload.newCustomerPurchaseState, ({ id }) => id, "replace"),
      };
    }

    case "LOADED_CUSTOMER_PURCHASE_STATE": {
      return {
        ...state,
        isLoading: false,
        data: updateMatchInArray(state.data, action.payload.purchaseState, ({ id }) => id, "replace"),
      };
    }

    default: {
      return state;
    }
  }
};
