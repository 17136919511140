import "./__root.css";

import { Outlet, ScrollRestoration, createRootRoute, useRouter } from "@tanstack/react-router";
import config from "@vendor-app/config";
import React, { Suspense, lazy, useEffect } from "react";

import { ErrorBoundary } from "@vendor-app/app/_sharedComponents/ErrorBoundary";

const TanStackRouterDevtools =
  config.environment === "production"
    ? () => null
    : lazy(() => import("@tanstack/router-devtools").then(mod => ({ default: mod.TanStackRouterDevtools })));

/**
 * This hook intercepts link clicks and navigates using the router instead of the default browser behavior.
 * This is necessary because the default browser behavior would cause a full page reload, which is not what we want.
 * There are some places where we can't use the `Link` component from `@tanstack/react-router` because `@shopify/polaris`
 * uses its own `Link` component, which renders an `a` tag with an `href` attribute, and we can't change that.
 */
function useInterceptLinkClicks() {
  const router = useRouter();

  useEffect(() => {
    function handleClick(event: MouseEvent) {
      const target = event.target as HTMLElement;
      const anchor = target.closest("a");

      if (
        !anchor ||
        anchor.origin !== window.location.origin ||
        anchor.hasAttribute("download") ||
        anchor.href.endsWith(".pdf")
      ) {
        return;
      }

      event.preventDefault();

      const url = new URL(anchor.href);
      void router.navigate({ to: url.pathname + url.search + url.hash });
    }

    document.addEventListener("click", handleClick);

    return () => document.removeEventListener("click", handleClick);
  }, [router]);
}

function RootRoute() {
  useInterceptLinkClicks();

  return (
    <React.Fragment>
      <ScrollRestoration />
      <Outlet />
      <Suspense>
        <TanStackRouterDevtools position="bottom-right" />
      </Suspense>
    </React.Fragment>
  );
}

export const Route = createRootRoute({
  component: () => <RootRoute />,
  errorComponent: ({ error, info }) => <ErrorBoundary error={error} info={info} />,
});
