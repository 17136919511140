import {
  ICustomerRelationshipShallow,
  IPaginatedCustomerRelationshipShallow,
} from "@smartrr/shared/entities/CustomerRelationship";

import { SmartrrVendorSubReducer } from "@vendor-app/app/_state/typedVendorRedux";

export interface ICustomersReducerState extends IPaginatedCustomerRelationshipShallow {
  isLoading: boolean;
  customer: ICustomerRelationshipShallow | null;
}

const initialState: ICustomersReducerState = {
  isLoading: true,
  totalCount: 0,
  totalPages: 0,
  pageSize: 0,
  pageNumber: 0,
  data: [],
  customer: null,
};

export const customerRelationsReducer: SmartrrVendorSubReducer<ICustomersReducerState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "SELECT_ACTIVE_ORGANIZATION": {
      return initialState;
    }

    case "LOADING_CUSTOMER":
    case "CHANGE_CUSTOMER_POINTS":
    case "CHANGE_CUSTOMER_BIRTHDAY":
    case "UPDATING_CUSTOMER_ADDRESS":
    case "LOADING_CUSTOMERS": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "ERROR_LOADING_CUSTOMER":
    case "ERROR_CHANGING_CUSTOMER_BIRTHDAY":
    case "ERROR_CHANGING_CUSTOMER_POINTS":
    case "ERROR_UPDATING_CUSTOMER_ADDRESS":
    case "ERROR_LOADING_CUSTOMERS": {
      return {
        ...state,
        isLoading: false,
      };
    }

    case "LOADED_CUSTOMERS": {
      return {
        ...state,
        isLoading: false,
        ...action.payload.customers,
      };
    }

    case "LOADED_CUSTOMER": {
      const indexOfExistingCustomer = state.data.findIndex(
        customer => customer.id === action.payload.customer.id
      );
      if (indexOfExistingCustomer > -1) {
        const customers = [...state.data];

        customers[indexOfExistingCustomer] = {
          ...action.payload.customer,
        };

        return {
          ...state,
          isLoading: false,
          customer: action.payload.customer,
          data: customers,
        };
      }

      return {
        ...state,
        isLoading: false,
        customer: action.payload.customer,
        data: [...state.data, action.payload.customer],
      };
    }

    case "CHANGED_CUSTOMER_BIRTHDAY": {
      const indexOfExistingCustomer = state.data.findIndex(
        customer => customer.id === action.payload.customerRelationshipId
      );
      if (indexOfExistingCustomer > -1) {
        const customers = [...state.data];
        customers[indexOfExistingCustomer].birthdayDate = action.payload.birthdayDate;

        return {
          ...state,
          isLoading: false,
          data: customers,
        };
      }

      return {
        ...state,
        isLoading: false,
      };
    }

    case "CHANGED_CUSTOMER_POINTS": {
      const indexOfExistingCustomer = state.data.findIndex(
        customer => customer.id === action.payload.customerRelationshipId
      );
      if (indexOfExistingCustomer > -1) {
        const customers = [...state.data];
        customers[indexOfExistingCustomer].orgPoints = action.payload.orgPoints;

        return {
          ...state,
          isLoading: false,
          data: customers,
        };
      }

      return {
        ...state,
        isLoading: false,
      };
    }

    case "UPDATED_CUSTOMER_ADDRESS": {
      const indexOfExistingCustomer = state.data.findIndex(
        customer => customer.id === action.payload.customerRelationshipId
      );
      if (indexOfExistingCustomer > -1) {
        const customers = [...state.data];
        customers[indexOfExistingCustomer].defaultAddress = action.payload.defaultAddress;

        return {
          ...state,
          isLoading: false,
          data: customers,
        };
      }

      return {
        ...state,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};
