import { pickBy } from "lodash";
import { parse, stringify } from "qs";

export function updateQueryParams(paramsObj: object) {
  if (typeof window !== "undefined") {
    const newParamsObj = pickBy(
      { ...parse(window.location.search, { ignoreQueryPrefix: true }), ...paramsObj },
      Boolean
    );
    const newQueryString = stringify(newParamsObj, { addQueryPrefix: true });
    const { protocol, host, pathname } = window.location;
    window.history.replaceState("", "", `${protocol}//${host}${pathname}${newQueryString}`);
  }
}
