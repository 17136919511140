import { prefixClassName } from "./components/primitives";

export const ENV = {
  PROD: "production",
  DEV: "development",
  TEST: "test",
  STAGING: "staging",
  SANDBOX: "sandbox",
};

export const LOGGING_ENVIRONMENTS = [ENV.PROD, ENV.STAGING, ENV.SANDBOX];

export const NODE_ENV = process.env.NODE_ENV || ENV.DEV;

export const IS_PROD = () => NODE_ENV === ENV.PROD;
export const IS_DEV = () => NODE_ENV === ENV.DEV;
export const IS_TEST = () => NODE_ENV === ENV.TEST;
export const IS_STAGING = () => NODE_ENV === ENV.STAGING;
export const IS_SANDBOX = () => NODE_ENV === ENV.SANDBOX;

export const IS_STAGING_HOST = (hostname: string) => {
  return (
    hostname.includes("staging.smartrr.com") ||
    hostname.includes("smartrr-staging.myshopify.com") ||
    // eslint-disable-next-line security/detect-unsafe-regex
    /^smartr{2}-staging(?:-[\da-z]+)+\.myshopify\.com$/.test(hostname)
  );
};

export const IS_SANDBOX_HOST = (hostname: string) => {
  return (
    hostname.includes("sandbox.smartrr.com") ||
    hostname.includes("smartrr-sandbox.myshopify.com") ||
    // eslint-disable-next-line security/detect-unsafe-regex
    /^smartr{2}-sandbox(?:-[\da-z]+)+\.myshopify\.com$/.test(hostname)
  );
};

// Helper functions
export const IS_BROWSER = typeof window !== "undefined" && typeof window.document !== "undefined";
export const SHOPIFY_APP_ID = process.env.SHOPIFY_APP_ID;

// Frontend Environment Checks
export const IS_STAGING_FRONTEND = IS_BROWSER && (IS_STAGING_HOST(window.location.hostname) || IS_STAGING());
export const IS_SANDBOX_FRONTEND = IS_BROWSER && (IS_SANDBOX_HOST(window.location.hostname) || IS_SANDBOX());

const appApiKeyPropertyName = "appApiKey";
export function getAppApiKey(shop: string): string {
  return `${shop}_${appApiKeyPropertyName}`;
}

// Host configuration
const BASE_DOMAIN = "smartrr.com";
const DEV_DOMAIN = "smartrr-dev.local.com";

// Determines the host prefix based on the environment
const getHostPrefix = (isStaging: boolean, isSandbox: boolean): string =>
  isStaging ? "staging." : isSandbox ? "sandbox." : "";

// Determines the base host for the front-end
const getFrontEndHostBase = (isDev: boolean, hostPrefix: string): string =>
  isDev ? DEV_DOMAIN : `${hostPrefix}${BASE_DOMAIN}`;

// Generates the full host based on environment and prefix type
const getPrefixedHost = (
  prefixType: "app." | "api.",
  isTest: boolean,
  isDev: boolean,
  frontEndHostBase: string
): string => {
  if (isTest) {
    return prefixType === "app." ? `app.test.${BASE_DOMAIN}` : `test.${BASE_DOMAIN}`;
  }

  if (isDev) {
    return frontEndHostBase;
  }

  return `${prefixType}${frontEndHostBase}`;
};

// Export host configurations
const HOST_PREFIX = getHostPrefix(IS_STAGING_FRONTEND, IS_SANDBOX_FRONTEND);
const FRONT_END_HOST_BASE = getFrontEndHostBase(IS_DEV(), HOST_PREFIX);
const appPrefixedHost = getPrefixedHost("app.", IS_TEST(), IS_DEV(), FRONT_END_HOST_BASE);
const apiPrefixedHost = getPrefixedHost("api.", IS_TEST(), IS_DEV(), FRONT_END_HOST_BASE);
export { HOST_PREFIX, FRONT_END_HOST_BASE, appPrefixedHost, apiPrefixedHost };

export const NO_OP_CALLBACK = () => {
  /* NO_OP */
};

// Route Prefixes
export const vendorApiRoutePrefix = "/vendor";
export const adminRoutePrefix = "/admin";
export const adminConfigRoutePrefix = `${adminRoutePrefix}/configure`;
export const customerPortalRoutePrefix = "/customer";

// Constants
// NOTE: THIS VALUE IS EMBEDDED IN LIQUID FILES, BE CAREFUL CHANGING
export const NUMBER_OF_PAST_ORDER_DATES_TO_DISPLAY = 3;
export const jwtPropertyName = "auth_token";
export const shopUrlPropertyName = "shop";
export const fromShopifyAppPropertyName = "fromShopifyApp";
export const SELLING_PLAN_GROUP_ADDON_TAG = "[addon]";
export const SELLING_PLAN_GROUP_TRENDING_LISTS_TAG = "[trending]";
export const SELLING_PLAN_GROUP_HIDDEN_TAG = "[hidden]";
export const HIDDEN_CLASS = prefixClassName("hidden");
export const expiredAuthMessage = "Expired Auth";
export const DEFAULT_VARIANT_TITLE = "Default Title";
export const SMARTRR_CUSTOM_ATTRIBUTES_VALUE = "_smartrr_info";
export const SMARTRR_PRODUCT_TAG = "SmartrrFilter";
// 1-9 is considered as system priority and can be set only by dev team
export const SMARTRR_SCRIPT_DEFAULT_USER_DEFINED_PRIORITY = 10;
export const ERASED_ORDER_NOTE = "shouldEraseNote";
export const SCRUB_STRING = "CUSTOMER_ERASED";
export const ELEMENT_NOT_FOUND = -1;

// release
export const RELEASE = process.env.RELEASE || `release-${new Date().toLocaleDateString().replaceAll("/", "-")}`;

export enum VENDOR_API_HEADERS {
  SMARTRR_ACCESS_TOKEN = "x-smartrr-access-token",
  SMARTRR_AUTH_TOKEN = "Authorization",
  SHOPIFY_SESSION_TOKEN = "x-shopify-session-token",
}

export enum CUSTOMER_API_HEADERS {
  SIGNATURE = "x-customer-portal-signature",
  SHOPIFY_CUSTOMER_ID = "x-shopify-customer-id",
  EXPIRATION = "x-customer-portal-exp",
  ADMIN_PREVIEW_ID = "x-shopify-admin-preview-id",
}

export enum SHOPIFY_HEADERS {
  VERSION = "x-shopify-api-version",
  HMAC_SHA_256 = "x-shopify-hmac-sha256",
  PRODUCT_ID = "x-shopify-product-id",
  SHOP_DOMAIN = "x-shopify-shop-domain",
  TOPIC = "x-shopify-topic",
  WEBHOOK_ID = "x-shopify-webhook-id",
}

export enum SHOPIFY_ERRORS {
  INVALID_PRODUCT_ID = "Invalid Shopify Product Id",
  INVALID_PRODUCT = "Invalid Shopify Product",
  INVALID_SIGNATURE = "Invalid Shopify Signature.",
  INVALID_TOPIC = "Invalid Shopify Topic.",
  PRODUCT_NOT_FOUND = "Product Data Not Found.",
  ORGANIZATION_NOT_FOUND = "Organization Data Not Found.",
  NOT_FOUND_ROUTE = "Route Not Found.",
  DEFAULT = "Something went wrong processing this request.",
}

// Allows us to associate errors with specific features
export enum FEATURE {
  CustomerConversationService = "CustomerConversationService",
  GorgiasIntegrationCreation = "GorgiasIntegrationCreation",
  PostscriptIntegration = "PostscriptIntegration",
  ShopifyFlow = "ShopifyFlow",
}
