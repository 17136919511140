/* eslint-disable no-console */
import * as Sentry from "@sentry/react";

export const captureException = (message: string, cause?: any): void => {
  const error = new Error(message);
  const scope = new Sentry.Scope();
  scope.setTag("isSmartrr", "true");

  (error as any).cause = cause;
  Sentry.captureException(error, scope);
  if (cause) {
    console.error(message, cause);
  } else {
    console.error(message);
  }
};
