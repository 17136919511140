import { ISmsAddon } from "@smartrr/shared/entities/SmsAddon";

import { SmartrrVendorSubReducer } from "@vendor-app/app/_state/typedVendorRedux";

export interface ISmsAddonReducerState {
  isInitializing: boolean;
  isLoading: boolean;
  smsAddons?: ISmsAddon[];
  isUpdating: boolean;
}

const initialState: ISmsAddonReducerState = {
  isInitializing: true,
  isLoading: true,
  isUpdating: false,
};

export const smsAddonReducer: SmartrrVendorSubReducer<ISmsAddonReducerState> = (
  state = initialState,
  action
): ISmsAddonReducerState => {
  switch (action.type) {
    case "LOADING_SMS_ADDONS": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "ERROR_LOADING_SMS_ADDONS": {
      return {
        ...state,
        isLoading: false,
      };
    }

    case "LOADED_SMS_ADDONS": {
      return {
        ...state,
        isLoading: false,
        isInitializing: false,
        smsAddons: action.payload.smsAddons,
      };
    }

    case "UPDATING_SMS_ADDONS": {
      return {
        ...state,
        isUpdating: true,
      };
    }

    case "ERROR_UPDATING_SMS_ADDONS": {
      return {
        ...state,
        isUpdating: false,
      };
    }

    case "UPDATED_SMS_ADDONS": {
      return {
        ...state,
        isUpdating: false,
        smsAddons: action.payload.updatedSmsAddons,
      };
    }

    default: {
      return state;
    }
  }
};
