import { merge } from "lodash";

type MergeRule = "existingValue" | "newValue" | "replace";

export function updateMatchInArray<T>(
  arr: T[],
  value: T,
  idAccessor: (v: T) => string,
  mergeRule: MergeRule = "newValue", // which side wins if merging. "newValue" = the new value wins, "existingValue" = the existing value wins
  concatIfNotFound = true
) {
  const valueId = idAccessor(value);
  const indexOfExistingValue = arr.findIndex(p => idAccessor(p) === valueId);

  if (indexOfExistingValue !== -1) {
    return [
      ...arr.slice(0, indexOfExistingValue),
      mergeRule === "newValue"
        ? merge({}, arr[indexOfExistingValue], value)
        : mergeRule === "existingValue"
        ? merge({}, value, arr[indexOfExistingValue])
        : value,
      ...arr.slice(indexOfExistingValue + 1),
    ];
  } else if (concatIfNotFound) {
    return arr.concat(value);
  }
  return arr;
}
