import { IPayoutMethod } from "@smartrr/shared/entities/PayoutMethod";

import { SmartrrVendorSubReducer } from "@vendor-app/app/_state/typedVendorRedux";

export interface IStripeConnectReducerState {
  isLoading: boolean;
  errorMessage: string | null;
  connectedStripePayoutMethod?: IPayoutMethod | null;
}

const initialState: IStripeConnectReducerState = {
  isLoading: true,
  errorMessage: null,
};

export const stripeConnectReducer: SmartrrVendorSubReducer<IStripeConnectReducerState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "LOADING_STRIPE_CONNECT": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "LOADED_STRIPE_CONNECT": {
      return {
        ...state,
        isLoading: false,
        connectedStripePayoutMethod: action.payload.connectedStripePayoutMethod,
        errorMessage: null,
      };
    }

    case "ERROR_CONNECTING_STRIPE_ACCOUNT": {
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload.errorMessage,
      };
    }

    case "REMOVING_STRIPE_CONNECT": {
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
      };
    }

    case "REMOVED_STRIPE_CONNECT": {
      return {
        ...state,
        isLoading: false,
        connectedStripePayoutMethod: null,
        errorMessage: null,
      };
    }

    default: {
      return state;
    }
  }
};
