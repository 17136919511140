import { jwtPropertyName } from "@smartrr/shared/constants";
import { IVendorUser } from "@smartrr/shared/entities/VendorUser";
import { getVendorBearerToken } from "@smartrr/shared/utils/authToken";
import { setInLocalStorage, unsetInLocalStorage } from "@smartrr/shared/utils/localStorageAccess";

import { SmartrrVendorSubReducer } from "@vendor-app/app/_state/typedVendorRedux";
import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";

export interface IUserReducerState {
  isLoading: boolean;
  user: IVendorUser | null;
  auth_token: string | null;
}

const initialState: IUserReducerState = {
  isLoading: true,
  user: null,
  [jwtPropertyName]: getVendorBearerToken() || null,
};

export const userReducer: SmartrrVendorSubReducer<IUserReducerState> = (state = initialState, action) => {
  switch (action.type) {
    case "LOADING_USER": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "LOADED_VENDOR_PORTAL":
    case "LOADED_USER": {
      const jwtToken = action.payload[jwtPropertyName];
      if (jwtToken) {
        setInLocalStorage(jwtPropertyName, jwtToken);
      } else {
        unsetInLocalStorage(jwtPropertyName);
      }

      return {
        ...state,
        isLoading: false,
        [jwtPropertyName]: action.payload[jwtPropertyName],
        user: action.payload.user,
      };
    }

    case "LOCAL_AUTH": {
      return {
        ...state,
        isLoading: false,
        user: state.user
          ? {
              ...state.user,
              ...action.payload.user,
            }
          : action.payload.user,
      };
    }

    case "LOG_OUT": {
      return {
        ...state,
        isLoading: false,
        [jwtPropertyName]: null,
        user: null,
      };
    }

    default: {
      return state;
    }
  }
};

export function useIsSuperUserSelector() {
  return useSmartrrVendorSelector(state => !!state.auth.user?.isSuperUser);
}
