export function loadZendeskChat() {
  const ua = navigator.userAgent.toLowerCase();
  const platform = navigator.platform.toLowerCase();
  const platformName = /ip(?:ad|od|hone)/.test(ua)
    ? "ios"
    : (ua.match(/webos|android/) ?? platform.match(/mac|win|linux/) ?? ["other"])[0];
  const isMobile = /ios|android|webos/.test(platformName);

  if (!isMobile) {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.defer = true;
    script.id = "ze-snippet";

    script.src = "https://static.zdassets.com/ekr/snippet.js?key=7c06bab1-51c4-45ff-ad4f-b344e17b4625";
    document.getElementsByTagName("head")[0].append(script);
  }
}
