import { createRouter } from "@tanstack/react-router";
import ReactGA from "react-ga4";

import { routeTree } from "./routeTree.gen";

export const router = createRouter({ routeTree });

declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

router.history.subscribe(() => {
  ReactGA.send({ hitType: "pageview", page: router.history.location.href });
});
