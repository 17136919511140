import { IAccountPlan } from "@smartrr/shared/entities/AccountPlan";

import { SmartrrVendorSubReducer } from "@vendor-app/app/_state/typedVendorRedux";

export interface ISuperUserReducerState {
  accountPlan: IAccountPlan | null;
  remainingTrialDays: number;
  isLoading: boolean;
  errorMessage: string;
}

const initialState: ISuperUserReducerState = {
  accountPlan: null,
  remainingTrialDays: 0,
  isLoading: true,
  errorMessage: "",
};

export const superUserReducer: SmartrrVendorSubReducer<ISuperUserReducerState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "LOADING_TRIAL": {
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
      };
    }

    case "ERROR_LOADING_TRIAL": {
      return {
        ...state,
        isLoading: false,
        accountPlan: null,
        errorMessage: action.payload.errorMessage,
      };
    }

    case "LOADED_TRIAL": {
      return {
        ...state,
        isLoading: false,
        accountPlan: action.payload.accountPlan || null,
        remainingTrialDays: action.payload.remainingTrialDays,
        errorMessage: "",
      };
    }

    case "REACTIVATING_TRIAL": {
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
      };
    }

    case "ERROR_REACTIVATING_TRIAL": {
      return {
        ...state,
        isLoading: false,
        accountPlan: null,
        errorMessage: action.payload.errorMessage,
      };
    }

    case "REACTIVATED_TRIAL": {
      return {
        ...state,
        isLoading: false,
        accountPlan: action.payload.accountPlan || null,
        errorMessage: "",
      };
    }

    case "EXTENDING_TRIAL": {
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
      };
    }

    case "ERROR_EXTENDING_TRIAL": {
      return {
        ...state,
        isLoading: false,
        accountPlan: null,
        errorMessage: action.payload.errorMessage,
      };
    }

    case "EXTENDED_TRIAL": {
      return {
        ...state,
        isLoading: false,
        accountPlan: action.payload.accountPlan || null,
        errorMessage: "",
      };
    }

    case "CANCELING_APP_SUBSCRIPTION": {
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
      };
    }

    case "ERROR_CANCELING_APP_SUBSCRIPTION": {
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload.errorMessage,
      };
    }

    case "CANCELED_APP_SUBSCRIPTION": {
      return {
        ...state,
        isLoading: false,
        accountPlan: null,
        errorMessage: "",
      };
    }

    case "EDITING_PLAN_FEATURES": {
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
      };
    }

    case "ERROR_EDITING_PLAN_FEATURES": {
      return {
        ...state,
        isLoading: false,
        accountPlan: null,
        errorMessage: action.payload.errorMessage,
      };
    }

    case "EDITED_PLAN_FEATURES": {
      return {
        ...state,
        isLoading: false,
        accountPlan: action.payload.accountPlan || null,
        errorMessage: "",
      };
    }

    default: {
      return state;
    }
  }
};
