import config from "@vendor-app/config";
import { onCLS, onINP, onLCP, type CLSMetric, type INPMetric, type LCPMetric } from "web-vitals";
import ReactGA from "react-ga4";

function sendToAnalytics(metric: CLSMetric | INPMetric | LCPMetric) {
  if (ReactGA.isInitialized) {
    ReactGA.gtag("event", "web-vitals", {
      value: metric.delta,
      metric_id: metric.id,
      metric_name: metric.name,
      metric_value: metric.value,
      metric_delta: metric.delta,
      metric_rating: metric.rating,
    });
  } else if (config.environment === "development") {
    // eslint-disable-next-line no-console
    console.log({ metric });
  }
}

export const loadWebVitals = () => {
  onCLS(sendToAnalytics);
  onINP(sendToAnalytics);
  onLCP(sendToAnalytics);
};
