import { isNil, mergeWith } from "lodash";
import { defaultCustomerPortalThemeConfig } from "./defaultThemeConfig";
import { ICustomerPortalTheme } from "../entities/CustomerPortalTheme";

export function numberToPxString(number?: number) {
  return isNil(number) ? undefined : `${number}px`;
}

export function pxStringWithMin(min: number, pxString?: string | number | (string & {})) {
  if (!pxString || typeof pxString !== "string" || !pxString.endsWith("px")) {
    return pxString;
  }

  const pxNumber = +pxString.slice(0, -2);
  return numberToPxString(Math.max(min, pxNumber));
}

export function multiplyPxString(factor: number, pxString?: string | number | (string & {})) {
  if (!pxString || typeof pxString !== "string" || !pxString.endsWith("px")) {
    return pxString;
  }

  const pxNumber = +pxString.slice(0, -2);
  return numberToPxString(factor * pxNumber);
}

export function mergeThemeWithDefault(theme: ICustomerPortalTheme): ICustomerPortalTheme {
  return mergeWith<{}, ICustomerPortalTheme, Partial<ICustomerPortalTheme>>(
    {},
    defaultCustomerPortalThemeConfig,
    theme,
    (a, b) => (b === null || b === undefined ? a : undefined)
  );
}

export function pxStringToNumber(pxString?: string | number) {
  if (isNil(pxString) || typeof pxString === "number") {
    return pxString;
  }
  return Number(pxString.replace("px", ""));
}
