import { ICancellationReason } from "@smartrr/shared/entities/CancellationRelationship";

import { SmartrrVendorSubReducer } from "@vendor-app/app/_state/typedVendorRedux";

export interface ICancellationReasonReducerState {
  isInitializing: boolean;
  isLoading: boolean;
  cancellationReasons?: ICancellationReason[];
  isUpdating: boolean;
}

const initialState: ICancellationReasonReducerState = {
  isInitializing: true,
  isLoading: true,
  isUpdating: false,
};

export const cancellationReasonReducer: SmartrrVendorSubReducer<ICancellationReasonReducerState> = (
  state = initialState,
  action
): ICancellationReasonReducerState => {
  switch (action.type) {
    case "LOADING_CANCELLATION_REASONS": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "ERROR_LOADING_CANCELLATION_REASONS": {
      return {
        ...state,
        isLoading: false,
      };
    }

    case "LOADED_CANCELLATION_REASONS": {
      return {
        ...state,
        isLoading: false,
        isInitializing: false,
        cancellationReasons: action.payload.cancellationReasons,
      };
    }

    default: {
      return state;
    }
  }
};
