import { parse } from "qs";

import { Primitive } from "./PrimitiveKeys";

interface ParsedQsWithBooleans {
  [key: string]: undefined | string | string[] | ParsedQsWithBooleans | ParsedQsWithBooleans[] | boolean;
}

export function getParsedQuery(): ParsedQsWithBooleans {
  return typeof window === "undefined"
    ? {}
    : recursivelyConvertBooleanStringsToBooleans(parse(window.location.search, { ignoreQueryPrefix: true }));
}

export function getParsedQueryPrimitives(): { [key: string]: Primitive } {
  const parsedQuery = getParsedQuery();

  for (const key of Object.keys(parsedQuery)) {
    const value = parsedQuery[key];
    if ((typeof value !== "object" && typeof value !== "function") || value === null) {
      delete parsedQuery[key];
    }
  }

  // hacky but nbd here
  return parsedQuery as any;
}

// sloppy but want booleans instead of strings
function recursivelyConvertBooleanStringsToBooleans(parsedQueryString: object): ParsedQsWithBooleans {
  const result: ParsedQsWithBooleans = parsedQueryString as ParsedQsWithBooleans;
  for (const key of Object.keys(result)) {
    const value = result[key];
    const valueType = typeof value;
    switch (valueType) {
      case "string": {
        const cleanedValue = (value as string).trim().toLowerCase();
        if (cleanedValue === "true") {
          result[key] = true;
        } else if (cleanedValue === "false") {
          result[key] = false;
        }

        break;
      }
      case "undefined": {
        result[key] = true;

        break;
      }
      case "object": {
        recursivelyConvertBooleanStringsToBooleans(value as ParsedQsWithBooleans);

        break;
      }
      // No default
    }
  }

  return result;
}
