import { IBillWithPurchStAndCustomerRelationshipPaginatedResponse } from "@smartrr/shared/entities/Billing";
import { findIndex } from "lodash";

import { SmartrrVendorSubReducer } from "@vendor-app/app/_state/typedVendorRedux";

export interface IBillsReducerState extends IBillWithPurchStAndCustomerRelationshipPaginatedResponse {
  isLoading: boolean;
}

const initialState: IBillsReducerState = {
  isLoading: true,
  totalCount: 0,
  totalPages: 0,
  pageSize: 0,
  pageNumber: 0,
  data: [],
};

export const billsReducer: SmartrrVendorSubReducer<IBillsReducerState> = (state = initialState, action) => {
  switch (action.type) {
    case "SELECT_ACTIVE_ORGANIZATION": {
      return initialState;
    }

    case "LOADING_BILLS":
    case "BULK_RETRYING_BILLS":
    case "BULK_CANCELING_BILLS":
    case "LOADING_BILLS_BY_ID": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "ERROR_LOADING_BILLS_BY_ID":
    case "ERROR_BULK_CANCELING_BILLS":
    case "ERROR_BULK_RETRYING_BILLS":
    case "ERROR_LOADING_BILLS": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "BULK_RETRIED_BILLS":
    case "BULK_CANCELED_BILLS":
    case "LOADED_BILLS_BY_ID":
    case "LOADED_BILLS": {
      return {
        ...state,
        ...action.payload.paginatedBills,
        isLoading: false,
      };
    }

    case "RETRIED_BILL": {
      // Updates an individual bill from retry response.
      const { uniqueId } = action.payload.retriedBill;
      let bills = state.data;
      const index = findIndex(state.data, { uniqueId });
      if (index !== -1) {
        bills = [...state.data.slice(0, index), action.payload.retriedBill, ...state.data.slice(index + 1)];
      }
      return {
        ...state,
        data: bills,
      };
    }

    case "CANCELED_BILL": {
      // Updates an individual bill from retry response.
      const { uniqueId } = action.payload.canceledBill;
      let bills = state.data;
      const index = findIndex(state.data, { uniqueId });
      if (index !== -1) {
        bills = [...state.data.slice(0, index), action.payload.canceledBill, ...state.data.slice(index + 1)];
      }
      return {
        ...state,
        data: bills,
      };
    }
  }
  return state;
};
