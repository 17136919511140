import { Box, Collapse, Stack } from "@mui/material";
import { Banner, Button, Icon, LegacyCard, Modal, Text } from "@shopify/polaris";
import { AlertMinor, ClipboardMinor } from "@shopify/polaris-icons";
import { captureException } from "@smartrr/shared/utils/captureException";
import { copyToClipboard } from "@smartrr/shared/utils/copyToClipboard";
import React, { useState } from "react";

import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";

export const ErrorBoundary = ({
  useModalState = true,
  error,
  info,
}: {
  useModalState?: boolean;
  error?: unknown;
  info?: React.ErrorInfo;
}) => {
  const [open, setOpen] = useState(true);

  return useModalState ? (
    <ErrorMessageModal open={open} toggleModal={() => setOpen(!open)} error={error} info={info} />
  ) : (
    <ErrorMessage error={error} info={info} />
  );
};

export const ErrorMessage = ({ error, info }: { error?: unknown; info?: React.ErrorInfo }): JSX.Element => {
  const { addToast } = useToast();
  const [showError, setShowError] = useState(false);

  captureException("Uncaught Exception", error);

  return (
    <LegacyCard sectioned>
      <Stack direction="column" gap="20px">
        <Banner icon={AlertMinor} status="critical">
          <Text as="p" variant="bodyMd">
            Oops! This wasn&apos;t supposed to happen. Please refresh your page or contact support if the issue
            persists.
          </Text>
        </Banner>
        <Box
          sx={{
            border: "1px solid #E1E3E5",
            borderRadius: ".25rem",
          }}
        >
          <Stack direction="column" p="10px">
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Text as="p" variant="bodyMd" fontWeight="bold">
                Error logs
              </Text>
              <Stack
                direction="row"
                gap="10px"
                alignItems="center"
                sx={{
                  svg: {
                    cursor: "pointer",
                    fill: "var(--p-color-icon)",
                  },
                }}
              >
                <div
                  onClick={() =>
                    copyToClipboard(
                      (error ? error.toString() : "") + (info ? info.componentStack : ""),
                      "Copied to clipboard",
                      addToast
                    )
                  }
                >
                  <Icon source={ClipboardMinor} />
                </div>
                <Button onClick={() => setShowError(prev => !prev)}>{showError ? "Collapse" : "Expand"}</Button>
              </Stack>
            </Stack>
            <Collapse in={showError}>
              <Box
                maxHeight="350px"
                sx={{
                  background: "var(--p-color-bg-disabled)",
                  overflow: "auto",
                  whiteSpace: "pre-wrap",
                  fontFamily: "IBM Plex Mono, monospace",
                  padding: "10px",
                  mt: "10px",
                }}
              >
                <Text as="p" variant="bodyMd">
                  {!!error && error.toString()}
                  <br />
                  {!!info && info.componentStack}
                </Text>
              </Box>
            </Collapse>
          </Stack>
        </Box>
      </Stack>
    </LegacyCard>
  );
};

export const ErrorMessageModal = ({
  open,
  toggleModal,
  error,
  info,
}: {
  open: boolean;
  toggleModal: () => void;
  error?: unknown;
  info?: React.ErrorInfo;
}): JSX.Element => {
  captureException("Uncaught Exception", error);

  return (
    <Modal
      open={open}
      onClose={toggleModal}
      title="Something went wrong"
      secondaryActions={[
        {
          content: "Contact support",
          onAction() {
            window.open("mailto:support@smartrr.com");
          },
        },
      ]}
    >
      <ErrorMessage error={error} info={info} />
    </Modal>
  );
};
