import "./styles.css";

import { Icon, Spinner, Toast, ToastProps } from "@shopify/polaris";
import { CancelSmallMinor } from "@shopify/polaris-icons";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";

import { useLocation } from "@vendor-app/utils/useLocation";

export interface ToastContextType {
  addToast: (content: string, error?: boolean) => void;
}

export const ToastContext = React.createContext<ToastContextType>({
  addToast: () => null,
});

export const useToast = () => useContext(ToastContext);

export interface Props {
  children: React.ReactNode;
}

export function ToastProvider({ children }: Props): JSX.Element {
  const [toastContent, setToastContent] = useState<string | null>(null);
  const [isErrorToast, setIsErrorToast] = useState<boolean | undefined>(false);

  const addToast = (content: string, error?: boolean) => {
    setToastContent(content);
    setIsErrorToast(error);
  };

  return (
    <ToastContext.Provider
      value={{
        addToast,
      }}
    >
      {toastContent !== null && (
        <Toast content={toastContent} error={isErrorToast} onDismiss={() => setToastContent(null)} />
      )}
      {children}
    </ToastContext.Provider>
  );
}

export interface LoadingToastContextType {
  addLoadingToast: (isLoading: boolean) => void;
}

export const LoadingToastContext = React.createContext<LoadingToastContextType>({
  addLoadingToast: () => null,
});

export const useLoadingToast = () => useContext(LoadingToastContext);

export const StyledLoadingToastWrapper = styled.div`
  .Polaris-Frame-Toast {
    background-color: #fff !important;
  }
`;

export function LoadingToastProvider({ children }: Props): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>();
  const [showToast, setShowToast] = useState<boolean>(false);
  const [currPath, setCurrPath] = useState<string>();
  const location = useLocation();

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null;

    if (isLoading) {
      timeoutId = setTimeout(() => {
        setShowToast(true);
        setCurrPath(location.pathname);
      }, 100);
    } else {
      setShowToast(false);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [isLoading]);

  useEffect(() => {
    if (isLoading && currPath && currPath !== location.pathname) {
      setIsLoading(false);
      setCurrPath(location.pathname);
    }
  }, [location]);

  const addLoadingToast = (isLoading: boolean) => {
    setIsLoading(isLoading);
  };

  return (
    <LoadingToastContext.Provider
      value={{
        addLoadingToast,
      }}
    >
      {!!showToast && (
        <CustomToast
          content={"This is taking a little longer than normal, please wait..."}
          duration={150000}
          onDismiss={() => setIsLoading(false)}
          loadingToast
        />
      )}
      {children}
    </LoadingToastContext.Provider>
  );
}

export const CustomToast = ({
  content,
  duration,
  onDismiss,
  loadingToast,
}: ToastProps & { loadingToast?: boolean }) => {
  const [show, setShow] = useState(true);
  const [active, setActive] = useState(false);
  useEffect(() => {
    const animationTimer = setTimeout(() => {
      setActive(true);
    }, 1000);
    const timer = setTimeout(() => {
      setActive(false);
      setShow(false);
      onDismiss && onDismiss();
    }, duration);

    return () => {
      clearTimeout(timer);
      clearTimeout(animationTimer);
    };
  }, [duration, onDismiss]);

  const handleClose = () => {
    setShow(false);
    setActive(false);
    onDismiss && onDismiss();
  };

  return show ? (
    <div className={`Polaris-Frame-Toast ${loadingToast && "loading-toast"} ${active && "active"}`}>
      {loadingToast ? <Spinner size="small" /> : null}
      {content}
      <div className="Polaris-Icon-Container" onClick={handleClose}>
        <Icon source={CancelSmallMinor} />
      </div>
    </div>
  ) : null;
};
