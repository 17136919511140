import React, { CSSProperties } from "react";

import { BoxProps, FlexColumn, FlexRow } from "./index";

interface Props extends BoxProps {
  vertical?: boolean; // alias for direction="column"
  style?: CSSProperties;
  className?: string;
  children?: React.ReactNode;
}

export const Box = React.forwardRef<HTMLDivElement, Props>(
  ({ vertical, direction = "row", children, ...rest }, ref): JSX.Element => {
    const Component = !vertical && direction === "row" ? FlexRow : FlexColumn;
    return (
      <Component ref={ref} direction={vertical ? "column" : direction} {...rest}>
        {children}
      </Component>
    );
  }
);
