import { IAccountPlan, IAccountPlanConfig } from "@smartrr/shared/entities/AccountPlan";

import { SmartrrVendorSubReducer } from "@vendor-app/app/_state/typedVendorRedux";

export interface IAccountPlansReducerState {
  isLoadingActivePlan: boolean;
  activePlan: IAccountPlan | null;
  isLoadingConfigs: boolean;
  configs: IAccountPlanConfig[];
  isSyncingPlans: boolean;
  pendingPlan: IAccountPlan | null;
  freeTrialPlan: IAccountPlan | null;
  isSwitchingPlan: boolean;
  errorMessage: string;
  isLoadingFees: boolean;
}

const initialState: IAccountPlansReducerState = {
  isLoadingActivePlan: true,
  activePlan: null,
  isLoadingConfigs: false,
  configs: [],
  isSyncingPlans: false,
  pendingPlan: null,
  freeTrialPlan: null,
  isSwitchingPlan: false,
  errorMessage: "",
  isLoadingFees: false,
};

export const accountPlansReducer: SmartrrVendorSubReducer<IAccountPlansReducerState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "SELECT_ACTIVE_ORGANIZATION": {
      return initialState;
    }

    case "LOADING_ACTIVE_PLAN": {
      return {
        ...state,
        isLoadingActivePlan: true,
      };
    }

    case "ERROR_LOADING_ACTIVE_PLAN": {
      return {
        ...state,
        isLoadingActivePlan: false,
        activePlan: null,
      };
    }

    case "LOADED_VENDOR_PORTAL": {
      return {
        ...state,
        isLoadingActivePlan: false,
        activePlan: action.payload.accountPlan,
      };
    }

    case "LOADED_ACTIVE_PLAN": {
      return {
        ...state,
        isLoadingActivePlan: false,
        activePlan: action.payload.accountPlan || null,
      };
    }

    case "LOADING_ACCOUNT_PLAN_CONFIGS": {
      return {
        ...state,
        isLoadingConfigs: true,
      };
    }

    case "ERROR_LOADING_ACCOUNT_PLAN_CONFIGS": {
      return {
        ...state,
        isLoadingConfigs: false,
        configs: [],
      };
    }

    case "LOADED_ACCOUNT_PLAN_CONFIGS": {
      return {
        ...state,
        isLoadingConfigs: false,
        configs: action.payload.configs,
      };
    }

    case "SYNCING_ACCOUNT_PLANS": {
      return {
        ...state,
        isSyncingPlans: true,
      };
    }

    case "ERROR_SYNCING_ACCOUNT_PLANS": {
      return {
        ...state,
        isSyncingPlans: false,
        pendingPlan: null,
      };
    }

    case "SYNCED_ACCOUNT_PLANS": {
      return {
        ...state,
        isSyncingPlans: false,
        activePlan: action.payload.activePlan,
        freeTrialPlan: action.payload.freeTrialPlan,
        pendingPlan: action.payload.pendingPlan,
      };
    }

    case "SWITCHING_TO_ACCOUNT_PLAN": {
      return {
        ...state,
        isSwitchingPlan: true,
      };
    }

    case "ERROR_SWITCHING_TO_ACCOUNT_PLAN": {
      return {
        ...state,
        isSwitchingPlan: false,
      };
    }

    case "SWITCHED_TO_ACCOUNT_PLAN": {
      return {
        ...state,
        isSwitchingPlan: false,
        pendingPlan: action.payload.accountPlan, // New plan becomes pending
      };
    }

    case "CANCELING_APP_SUBSCRIPTION": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "ERROR_CANCELING_APP_SUBSCRIPTION": {
      return {
        ...state,
        isLoading: false,
      };
    }

    case "CANCELED_APP_SUBSCRIPTION": {
      return {
        ...state,
        isLoading: false,
        activePlan: null,
      };
    }

    case "EDITING_PLAN_FEES": {
      return {
        ...state,
        isLoadingFees: true,
        errorMessage: "",
      };
    }

    case "ERROR_EDITING_PLAN_FEES": {
      return {
        ...state,
        isLoadingFees: false,
        errorMessage: action.payload.errorMessage,
      };
    }

    case "EDITED_PLAN_FEES": {
      return {
        ...state,
        isLoadingFees: false,
        activePlan: action.payload.accountPlan || null,
        errorMessage: "",
      };
    }

    default: {
      return state;
    }
  }
};
