import { isNil } from "lodash";

let isLocalStorageAvailable = true;

try {
  if (typeof window === "undefined") {
    isLocalStorageAvailable = false;
  } else {
    window.localStorage.getItem("testing-valid-access-to-local-storage");
  }
} catch (error) {
  if (process.env.NODE_ENV !== "production") {
    console.info("localStorage is not available");
    if (error instanceof Error && error.message) {
      console.error(error.message);
    }
  }
  isLocalStorageAvailable = false;
}

const fallbackLocalStorage: { [key: string]: string } = {};

export function getFromLocalStorage(localStorageProperty: string): any {
  if (isLocalStorageAvailable && isLocalStorageAvailable) {
    const fromLocalStorage = window.localStorage.getItem(localStorageProperty);
    return isNil(fromLocalStorage) ? undefined : fromLocalStorage;
  }
  return fallbackLocalStorage[localStorageProperty];
}

export function setInLocalStorage(localStorageProperty: string, localStorageValue: string): void {
  if (isLocalStorageAvailable && isLocalStorageAvailable) {
    try {
      window.localStorage.setItem(localStorageProperty, localStorageValue);
    } catch (error) {
      // If the there is no space, we will get an error while trying to set a new item
      if (error instanceof Error) {
        console.error(error.message);
      }
    }
  } else {
    fallbackLocalStorage[localStorageProperty] = localStorageValue;
  }
}

export function unsetInLocalStorage(localStorageProperty: string): void {
  if (isLocalStorageAvailable) {
    window.localStorage.removeItem(localStorageProperty);
  } else {
    delete fallbackLocalStorage[localStorageProperty];
  }
}
