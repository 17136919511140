import { configureStore } from "@reduxjs/toolkit";

import { SmartrrStore } from "@vendor-app/app/_state/typedVendorRedux";

import { initialState as initialAppState, rootReducer } from "../reducers";

export function initStore(initialState = initialAppState): SmartrrStore {
  return configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
    middleware(getDefaultMiddleware) {
      return getDefaultMiddleware({ immutableCheck: false, serializableCheck: false });
    },
  });
}
