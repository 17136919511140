import { IS_BROWSER, shopUrlPropertyName } from "@smartrr/shared/constants";
import { getParsedQuery } from "@smartrr/shared/utils/frontendQueryUtils";
import {
  getFromLocalStorage,
  setInLocalStorage,
  unsetInLocalStorage,
} from "@smartrr/shared/utils/localStorageAccess";
import { updateQueryParams } from "@smartrr/shared/utils/windowLocationUtils";

let shopFromQueryOrLocalStorageCache: string | undefined;
let shopFromQueryOrLocalStorageCacheIsSet: boolean;
export function getShopUrlFromGlobals(): string | undefined {
  if (shopFromQueryOrLocalStorageCacheIsSet) {
    return shopFromQueryOrLocalStorageCache;
  }

  if (IS_BROWSER) {
    const localStorageValue = getFromLocalStorage(shopUrlPropertyName);
    const fromQueryString = getParsedQuery()[shopUrlPropertyName];
    shopFromQueryOrLocalStorageCache =
      // this order is important, query can override all
      (typeof fromQueryString === "string" ? fromQueryString : undefined) || localStorageValue || undefined;

    if (!localStorageValue && shopFromQueryOrLocalStorageCache) {
      setInLocalStorage(shopUrlPropertyName, shopFromQueryOrLocalStorageCache);
    }
  }

  shopFromQueryOrLocalStorageCacheIsSet = true;
  return shopFromQueryOrLocalStorageCache;
}

export function setShopGlobals(shopUrl: string | null): void {
  if (shopUrl) {
    setInLocalStorage(shopUrlPropertyName, shopUrl);
    updateQueryParams({ [shopUrlPropertyName]: shopUrl });

    // update cache
    shopFromQueryOrLocalStorageCache = shopUrl;
  } else {
    unsetInLocalStorage(shopUrlPropertyName);

    // clear cache
    shopFromQueryOrLocalStorageCache = undefined;
    shopFromQueryOrLocalStorageCacheIsSet = false;
  }
}
