import * as Sentry from "@sentry/react";
import ReactGA from "react-ga4";
import { AppProvider } from "@shopify/polaris";
import translations from "@shopify/polaris/locales/en.json";
import { LaunchDarklyOptions } from "@smartrr/shared/LaunchDarkly";
import { SentryDSN, environment, sampleRatesDefault } from "@smartrr/shared/services/Sentry/constants";
import { RouterProvider } from "@tanstack/react-router";
import { withLDProvider } from "launchdarkly-react-client-sdk";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";

import { initStore } from "./app/_state/store";
import { router } from "./router";
import { loadPendo } from "./scripts/loadPendo";
import { loadZendeskChat } from "./scripts/loadZendeskChat";

import "rsuite/dist/rsuite.min.css";
import { loadWebVitals } from "./utils/loadWebVitals";
import config from "@vendor-app/config";

Sentry.init({
  environment,
  dsn: SentryDSN.vendorPortal,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  ...sampleRatesDefault,
});

initializeGA();
loadPendo(config.pendo.clientToken);
loadZendeskChat();
loadWebVitals();

function App() {
  return (
    <Provider store={initStore()}>
      <AppProvider i18n={translations}>
        <RouterProvider router={router} />
      </AppProvider>
    </Provider>
  );
}

const AppWithLD = withLDProvider({
  clientSideID: config.launchDarkly.clientSideId,
  ...LaunchDarklyOptions,
})(App);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <AppWithLD />
  </React.StrictMode>
);

function initializeGA() {
  const measurementId = config.googleAnalytics.measurementId;
  if (!measurementId || measurementId === "G-") {
    return;
  }
  try {
    ReactGA.initialize(measurementId);
  } catch (error) {
    Sentry.captureException(error);
  }
}
