import {
  ISmartrrOrderStatus,
  ISmartrrOrderWithCustomerRelationshipPaginatedResponse,
} from "@smartrr/shared/entities/Order";
import { updateMatchInArray } from "@smartrr/shared/utils/updateMatchInArray";

import { SmartrrVendorSubReducer } from "@vendor-app/app/_state/typedVendorRedux";

export interface IOrdersReducerState extends ISmartrrOrderWithCustomerRelationshipPaginatedResponse {
  isLoading: boolean;
  isStreaming: boolean;
}

const initialState: IOrdersReducerState = {
  isLoading: true,
  isStreaming: false,
  totalCount: 0,
  pageSize: 0,
  pageNumber: 0,
  totalPages: 0,
  data: [],
};

export const ordersReducer: SmartrrVendorSubReducer<IOrdersReducerState> = (state = initialState, action) => {
  switch (action.type) {
    case "SELECT_ACTIVE_ORGANIZATION": {
      return initialState;
    }

    case "LOAD_PURCHASE_STATE_ORDERS":
    case "LOADING_ORDERS": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "ERROR_LOADING_PURCHASE_STATE_ORDERS":
    case "ERROR_LOADING_ORDERS": {
      return {
        ...state,
        isLoading: false,
      };
    }

    case "LOADED_PURCHASE_STATE_ORDERS":
    case "LOADED_ORDERS": {
      return {
        ...state,
        ...action.payload.paginatedOrders,
        isLoading: false,
      };
    }

    case "STREAMING_SHOPIFY_ORDERS_DATA": {
      return {
        ...state,
        isStreaming: true,
      };
    }

    case "STREAMING_SHOPIFY_ORDERS_DATA_ITEM": {
      return {
        ...state,
        data: action.payload.orders.reduce(
          (previouslyLoadedOrders, updatedOrder) =>
            updateMatchInArray(
              previouslyLoadedOrders,
              updatedOrder,
              ({ id }) => id,
              "newValue",
              false // we only update, don't insert if not found (as orders are paginated)
            ),
          state.data
        ),
      };
    }

    case "STREAMING_SHOPIFY_ORDERS_DATA_COMPLETE": {
      return {
        ...state,
        isStreaming: false,
      };
    }

    case "DELETING_ORDER": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "ERROR_DELETING_ORDER": {
      return {
        ...state,
        isLoading: false,
      };
    }

    case "DELETED_ORDER": {
      const toUpdate = state.data.find(o => o.id === action.payload.orderId)!;
      const newOrders = updateMatchInArray(
        state.data,
        {
          ...toUpdate,
          orderStatus: ISmartrrOrderStatus.CLOSED,
        },
        ({ id }) => id
      );

      return {
        ...state,
        isLoading: false,
        data: newOrders,
      };
    }

    case "OPENING_ORDER": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "ERROR_OPENING_ORDER": {
      return {
        ...state,
        isLoading: false,
      };
    }

    case "OPENED_ORDER": {
      const toUpdate = state.data.find(o => o.id === action.payload.orderId)!;
      const newOrders = updateMatchInArray(
        state.data,
        {
          ...toUpdate,
          orderStatus: ISmartrrOrderStatus.OPEN,
        },
        ({ id }) => id
      );

      return {
        ...state,
        isLoading: false,
        data: newOrders,
      };
    }

    default: {
      return state;
    }
  }
};
