import { SmartrrVendorSubReducer } from "@vendor-app/app/_state/typedVendorRedux";
import type { SubscriptionDetailsLineItem } from "@smartrr/shared/interfaces/lineitem/api";

export interface ISubscriptionDetailsState {
  isLoading: boolean;
  showRawContracts: boolean;
  showCancelSubscriptionConfirmation: boolean;
  showInvalidVariant: boolean;
  showReplaceVariantConfirmation: boolean;
  showSetNextOrderDate: boolean;
  showSetUnpauseDate: boolean;
  showBundleContentsModal: {
    showModal: boolean;
    lineItem: SubscriptionDetailsLineItem | null;
  };
  showShippingPrice: boolean;
  showAddItem: boolean;
  showSwapItem: boolean;
  showRemoveAllProductsWarning: boolean;
  showEditNote: boolean;
  showRemoveSubscriptionDiscount: boolean;
}

const initialState: ISubscriptionDetailsState = {
  isLoading: false,
  showRawContracts: false,
  showCancelSubscriptionConfirmation: false,
  showInvalidVariant: false,
  showReplaceVariantConfirmation: false,
  showSetNextOrderDate: false,
  showSetUnpauseDate: false,
  showBundleContentsModal: {
    showModal: false,
    lineItem: null,
  },
  showShippingPrice: false,
  showAddItem: false,
  showSwapItem: false,
  showRemoveAllProductsWarning: false,
  showEditNote: false,
  showRemoveSubscriptionDiscount: false,
};

export const subscriptionDetailsReducer: SmartrrVendorSubReducer<ISubscriptionDetailsState> = (
  state = initialState,
  action
): ISubscriptionDetailsState => {
  switch (action.type) {
    case "BEGIN_LOADING_SUBSCRIPTION_DETAILS": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "END_LOADING_SUBSCRIPTION_DETAILS": {
      return {
        ...state,
        isLoading: false,
      };
    }

    case "SUBSCRIPTION_DETAILS_SHOW_RAW_CONTRACTS": {
      return {
        ...state,
        showRawContracts: true,
      };
    }
    case "SUBSCRIPTION_DETAILS_HIDE_RAW_CONTRACTS": {
      return {
        ...state,
        showRawContracts: false,
      };
    }

    case "SUBSCRIPTION_DETAILS_SHOW_CANCEL_SUBSCRIPTION": {
      return {
        ...state,
        showCancelSubscriptionConfirmation: true,
      };
    }
    case "SUBSCRIPTION_DETAILS_HIDE_CANCEL_SUBSCRIPTION": {
      return {
        ...state,
        showCancelSubscriptionConfirmation: false,
      };
    }

    case "SUBSCRIPTION_DETAILS_SHOW_INVALID_VARIANT": {
      return {
        ...state,
        showInvalidVariant: true,
      };
    }
    case "SUBSCRIPTION_DETAILS_HIDE_INVALID_VARIANT": {
      return {
        ...state,
        showInvalidVariant: false,
      };
    }

    case "SUBSCRIPTION_DETAILS_SHOW_REPLACE_VARIANT": {
      return {
        ...state,
        showReplaceVariantConfirmation: true,
      };
    }
    case "SUBSCRIPTION_DETAILS_HIDE_REPLACE_VARIANT": {
      return {
        ...state,
        showReplaceVariantConfirmation: false,
      };
    }

    case "SUBSCRIPTION_DETAILS_SHOW_SET_PAUSE_DATE": {
      return {
        ...state,
        showSetUnpauseDate: true,
      };
    }

    case "SUBSCRIPTION_DETAILS_HIDE_SET_PAUSE_DATE": {
      return {
        ...state,
        showSetUnpauseDate: false,
      };
    }

    case "SUBSCRIPTION_DETAILS_SHOW_BUNDLE_CONTENT": {
      return {
        ...state,
        showBundleContentsModal: {
          showModal: true,
          lineItem: action.payload.lineItem,
        },
      };
    }

    case "SUBSCRIPTION_DETAILS_HIDE_BUNDLE_CONTENT": {
      return {
        ...state,
        showBundleContentsModal: {
          showModal: false,
          lineItem: null,
        },
      };
    }

    case "SUBSCRIPTION_DETAILS_SHOW_EDIT_SHIPPING": {
      return {
        ...state,
        showShippingPrice: true,
      };
    }
    case "SUBSCRIPTION_DETAILS_HIDE_EDIT_SHIPPING": {
      return {
        ...state,
        showShippingPrice: false,
      };
    }

    case "SUBSCRIPTION_DETAILS_SHOW_ADD_ITEM": {
      return {
        ...state,
        showAddItem: true,
      };
    }
    case "SUBSCRIPTION_DETAILS_HIDE_ADD_ITEM": {
      return {
        ...state,
        showAddItem: false,
      };
    }

    case "SUBSCRIPTION_DETAILS_SHOW_SWAP_ITEM": {
      return {
        ...state,
        showSwapItem: true,
      };
    }
    case "SUBSCRIPTION_DETAILS_HIDE_SWAP_ITEM": {
      return {
        ...state,
        showSwapItem: false,
      };
    }

    case "SUBSCRIPTION_DETAILS_SHOW_REMOVE_ALL_PRODUCTS_WARNING": {
      return {
        ...state,
        showRemoveAllProductsWarning: true,
      };
    }
    case "SUBSCRIPTION_DETAILS_HIDE_REMOVE_ALL_PRODUCTS_WARNING": {
      return {
        ...state,
        showRemoveAllProductsWarning: false,
      };
    }

    case "SUBSCRIPTION_DETAILS_SHOW_REMOVE_SUBSCRIPTION_DISCOUNT": {
      return {
        ...state,
        showRemoveSubscriptionDiscount: true,
      };
    }
    case "SUBSCRIPTION_DETAILS_HIDE_REMOVE_SUBSCRIPTION_DISCOUNT": {
      return {
        ...state,
        showRemoveSubscriptionDiscount: false,
      };
    }

    case "SUBSCRIPTION_DETAILS_SHOW_EDIT_NOTE": {
      return {
        ...state,
        showEditNote: true,
      };
    }
    case "SUBSCRIPTION_DETAILS_HIDE_EDIT_NOTE": {
      return {
        ...state,
        showEditNote: false,
      };
    }

    default: {
      return state;
    }
  }
};
