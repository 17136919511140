import * as yup from "yup";

import { ValidSortMethods } from "@smartrr/shared/utils/sortBy";

import { ISharedEntityFields } from "./shared/SharedEntityFields";
import { RequireNilFields } from "../utils/RequireNilFields";

export interface ICustomerPortalThemeFromDb extends ICustomerPortalTheme, ISharedEntityFields {}

export type FontWeight = "bold" | "normal" | "bolder" | "lighter";

export type ProductScrollType = "horizontal" | "vertical";

export type ResponsiveFontStyling = "static" | "responsive";

export const fontArray = [
  "Mono",
  "Sans-serif",
  "Serif",
  "Abel",
  "Abril Fatface",
  "Agmena",
  "Akko",
  "Alegreya",
  "Alegreya Sans",
  "Alfie",
  "Americana",
  "Amiri",
  "Anonymous Pro",
  "Antique Olive",
  "Arapey",
  "Archivo",
  "Archivo Narrow",
  "Arial",
  "Arimo",
  "Armata",
  "Arvo",
  "Asap",
  "Assistant",
  "Asul",
  "Avenir Next",
  "Avenir Next Rounded",
  "Azbuka",
  "Basic Commercial",
  "Basic Commercial Soft Rounded",
  "Baskerville No 2",
  "Bauer Bodoni",
  "Beefcakes",
  "Bembo Book",
  "Bernhard Modern",
  "Bio Rhyme",
  "Bitter",
  "Bodoni Poster Black",
  "Burlingame",
  "Cabin",
  "Cachet",
  "Cardamon",
  "Cardo",
  "Carter Sans",
  "Caslon Bold",
  "Caslon Old Face",
  "Catamaran",
  "Centaur",
  "Century Gothic",
  "Chivo",
  "Chong Modern",
  "Claire News Light",
  "Cooper BT Medium",
  "Courier New",
  "Crimson Text",
  "DIN Neuzeit Grotesk Light",
  "DIN Next",
  "DIN Next Slab",
  "Daytona",
  "Domine",
  "Dosis",
  "Electra",
  "Eurostile Next",
  "FF Meta",
  "FF Meta Serif",
  "FF Tisa",
  "FF Tisa Sans",
  "FF Unit",
  "FF Unit Rounded",
  "FF Unit Slab",
  "Fette Gotisch",
  "Fira Sans",
  "Fjalla One",
  "Friz Quadrata",
  "Frutiger Serif",
  "Futura",
  "Futura Black",
  "Garamond",
  "Geometric 415",
  "Georgia Pro",
  "Gill Sans Nova",
  "Glegoo",
  "Goudy Old Style",
  "Harmonia Sans",
  "Helvetica",
  "Humanist 521",
  "IBM Plex Sans",
  "ITC Avant Garde Gothic",
  "ITC Benguiat",
  "ITC Berkeley Old Style",
  "ITC Bodoni Seventytwo",
  "ITC Bodoni Twelve",
  "ITC Caslon No 224",
  "ITC Charter",
  "ITC Cheltenham",
  "ITC Clearface",
  "ITC Conduit",
  "ITC Esprit",
  "ITC Founders Caslon",
  "ITC Franklin Gothic",
  "ITC Galliard",
  "ITC Gamma",
  "ITC Goudy Sans",
  "ITC Johnston Medium",
  "ITC Mendoza Roman",
  "ITC Modern No 216 Medium",
  "ITC New Baskerville",
  "ITC New Esprit",
  "ITC New Veljovic",
  "ITC Novarese",
  "ITC Officina Sans",
  "ITC Officina Serif",
  "ITC Stepp",
  "ITC Stone Humanist Medium",
  "ITC Stone Informal",
  "ITC Stone Serif",
  "ITC Tapioca Medium",
  "Inconsolata",
  "Joanna Nova",
  "Joanna Sans Nova",
  "Josefin Sans",
  "Josefin Slab",
  "Kairos",
  "Kalam",
  "Karla",
  "Kreon",
  "Lato",
  "Laurentian",
  "Libelle",
  "Libre Baskerville",
  "Libre Franklin",
  "Linotype Didot",
  "Linotype Gianotten",
  "Linotype Really Medium",
  "Linotype Syntax Serif",
  "Lobster",
  "Lobster Two",
  "Lora",
  "Lucia",
  "Lucida Grande",
  "Luthersche Fraktur",
  "Madera",
  "Malabar",
  "Mariposa Sans",
  "Maven Pro",
  "Megrim",
  "Melior",
  "Memphis Medium",
  "Memphis Soft Rounded Medium",
  "Mentor Sans",
  "Merriweather Sans",
  "Metro Nova",
  "Modern No 20",
  "Monaco/Lucida Console",
  "Monotype Baskerville",
  "Monotype Bodoni",
  "Monotype Century Old Style Medium",
  "Monotype Goudy",
  "Monotype Goudy Modern",
  "Monotype Italian Old Style",
  "Monotype New Clarendon Medium",
  "Monotype News Gothic",
  "Monotype Sabon",
  "Montserrat",
  "Mouse Memoirs",
  "Muli",
  "Mundo Sans",
  "Neo Sans",
  "Neue Aachen",
  "Neue Frutiger 1450",
  "Neue Haas Unica",
  "Neue Plak",
  "Neue Swift",
  "Neuton",
  "Neuzeit Office",
  "Neuzeit Office Soft Rounded",
  "Neuzeit S",
  "New Century Schoolbook",
  "News 702",
  "News 705",
  "News Cycle",
  "News Gothic No 2",
  "News Plantin",
  "Nobile",
  "Noticia Text",
  "Noto Serif",
  "Nunito",
  "Nunito Sans",
  "Old Standard TT",
  "Open Sans",
  "Open Sans Condensed Light",
  "Optima nova",
  "Oswald",
  "Ovo",
  "Oxygen",
  "PMN Caecilia",
  "PT Mono",
  "PT Sans",
  "PT Sans Narrow",
  "PT Serif",
  "Pacifico",
  "Palatino",
  "Parma",
  "Perpetua",
  "Plantin",
  "Playfair Display",
  "Poppins",
  "Prata",
  "Prompt",
  "Quantico",
  "Quattrocento",
  "Quattrocento Sans",
  "Questrial",
  "Quicksand",
  "Quire Sans",
  "Rajdhani",
  "Raleway",
  "Really No 2",
  "Righteous",
  "Roboto",
  "Roboto Condensed",
  "Roboto Mono",
  "Roboto Slab",
  "Rockwell",
  "Rubik",
  "Sabon Next",
  "Sackers Square Gothic",
  "Sagrantino",
  "Scene",
  "Scherzo",
  "Shadows Into Light",
  "Slate",
  "Soho",
  "Soho Gothic",
  "Source Code Pro",
  "Source Sans Pro",
  "Stempel Schneidler",
  "Swiss 721",
  "Swiss 721 Rounded Bold",
  "Tenor Sans",
  "Tiemann",
  "Times New Roman",
  "Tinos",
  "Titillium Web",
  "Trade Gothic",
  "Trade Gothic Next",
  "Trebuchet MS",
  "Twentieth Century",
  "Ubuntu",
  "Unica One",
  "Univers Next",
  "Univers Next Typewriter",
  "Unna",
  "Vala",
  "Varela",
  "Varela Round",
  "Verdana Pro",
  "Volkhov",
  "Vollkorn",
  "Waza",
  "Wola",
  "Work Sans",
  "Ysobel",
  "Zurich",
  "Zurich Extended",
];

export type FontFamily =
  | "Mono"
  | "Sans-serif"
  | "Serif"
  | "Abel"
  | "Abril Fatface"
  | "Agmena"
  | "Akko"
  | "Alegreya"
  | "Alegreya Sans"
  | "Alfie"
  | "Americana"
  | "Amiri"
  | "Anonymous Pro"
  | "Antique Olive"
  | "Arapey"
  | "Archivo"
  | "Archivo Narrow"
  | "Arial"
  | "Arimo"
  | "Armata"
  | "Arvo"
  | "Asap"
  | "Assistant"
  | "Asul"
  | "Avenir Next"
  | "Avenir Next Rounded"
  | "Azbuka"
  | "Basic Commercial"
  | "Basic Commercial Soft Rounded"
  | "Baskerville No 2"
  | "Bauer Bodoni"
  | "Beefcakes"
  | "Bembo Book"
  | "Bernhard Modern"
  | "Bio Rhyme"
  | "Bitter"
  | "Bodoni Poster Black"
  | "Burlingame"
  | "Cabin"
  | "Cachet"
  | "Cardamon"
  | "Cardo"
  | "Carter Sans"
  | "Caslon Bold"
  | "Caslon Old Face"
  | "Catamaran"
  | "Centaur"
  | "Century Gothic"
  | "Chivo"
  | "Chong Modern"
  | "Claire News Light"
  | "Cooper BT Medium"
  | "Courier New"
  | "Crimson Text"
  | "DIN Neuzeit Grotesk Light"
  | "DIN Next"
  | "DIN Next Slab"
  | "Daytona"
  | "Domine"
  | "Dosis"
  | "Electra"
  | "Eurostile Next"
  | "FF Meta"
  | "FF Meta Serif"
  | "FF Tisa"
  | "FF Tisa Sans"
  | "FF Unit"
  | "FF Unit Rounded"
  | "FF Unit Slab"
  | "Fette Gotisch"
  | "Fira Sans"
  | "Fjalla One"
  | "Friz Quadrata"
  | "Frutiger Serif"
  | "Futura"
  | "Futura Black"
  | "Garamond"
  | "Geometric 415"
  | "Georgia Pro"
  | "Gill Sans Nova"
  | "Glegoo"
  | "Goudy Old Style"
  | "Harmonia Sans"
  | "Helvetica"
  | "Humanist 521"
  | "IBM Plex Sans"
  | "ITC Avant Garde Gothic"
  | "ITC Benguiat"
  | "ITC Berkeley Old Style"
  | "ITC Bodoni Seventytwo"
  | "ITC Bodoni Twelve"
  | "ITC Caslon No 224"
  | "ITC Charter"
  | "ITC Cheltenham"
  | "ITC Clearface"
  | "ITC Conduit"
  | "ITC Esprit"
  | "ITC Founders Caslon"
  | "ITC Franklin Gothic"
  | "ITC Galliard"
  | "ITC Gamma"
  | "ITC Goudy Sans"
  | "ITC Johnston Medium"
  | "ITC Mendoza Roman"
  | "ITC Modern No 216 Medium"
  | "ITC New Baskerville"
  | "ITC New Esprit"
  | "ITC New Veljovic"
  | "ITC Novarese"
  | "ITC Officina Sans"
  | "ITC Officina Serif"
  | "ITC Stepp"
  | "ITC Stone Humanist Medium"
  | "ITC Stone Informal"
  | "ITC Stone Serif"
  | "ITC Tapioca Medium"
  | "Inconsolata"
  | "Joanna Nova"
  | "Joanna Sans Nova"
  | "Josefin Sans"
  | "Josefin Slab"
  | "Kairos"
  | "Kalam"
  | "Karla"
  | "Kreon"
  | "Lato"
  | "Laurentian"
  | "Libelle"
  | "Libre Baskerville"
  | "Libre Franklin"
  | "Linotype Didot"
  | "Linotype Gianotten"
  | "Linotype Really Medium"
  | "Linotype Syntax Serif"
  | "Lobster"
  | "Lobster Two"
  | "Lora"
  | "Lucia"
  | "Lucida Grande"
  | "Luthersche Fraktur"
  | "Madera"
  | "Malabar"
  | "Mariposa Sans"
  | "Maven Pro"
  | "Megrim"
  | "Melior"
  | "Memphis Medium"
  | "Memphis Soft Rounded Medium"
  | "Mentor Sans"
  | "Merriweather Sans"
  | "Metro Nova"
  | "Modern No 20"
  | "Monaco/Lucida Console"
  | "Monotype Baskerville"
  | "Monotype Bodoni"
  | "Monotype Century Old Style Medium"
  | "Monotype Goudy"
  | "Monotype Goudy Modern"
  | "Monotype Italian Old Style"
  | "Monotype New Clarendon Medium"
  | "Monotype News Gothic"
  | "Monotype Sabon"
  | "Montserrat"
  | "Mouse Memoirs"
  | "Muli"
  | "Mundo Sans"
  | "Neo Sans"
  | "Neue Aachen"
  | "Neue Frutiger 1450"
  | "Neue Haas Unica"
  | "Neue Plak"
  | "Neue Swift"
  | "Neuton"
  | "Neuzeit Office"
  | "Neuzeit Office Soft Rounded"
  | "Neuzeit S"
  | "New Century Schoolbook"
  | "News 702"
  | "News 705"
  | "News Cycle"
  | "News Gothic No 2"
  | "News Plantin"
  | "Nobile"
  | "Noticia Text"
  | "Noto Serif"
  | "Nunito"
  | "Nunito Sans"
  | "Old Standard TT"
  | "Open Sans"
  | "Open Sans Condensed Light"
  | "Optima nova"
  | "Oswald"
  | "Ovo"
  | "Oxygen"
  | "PMN Caecilia"
  | "PT Mono"
  | "PT Sans"
  | "PT Sans Narrow"
  | "PT Serif"
  | "Pacifico"
  | "Palatino"
  | "Parma"
  | "Perpetua"
  | "Plantin"
  | "Playfair Display"
  | "Poppins"
  | "Prata"
  | "Prompt"
  | "Quantico"
  | "Quattrocento"
  | "Quattrocento Sans"
  | "Questrial"
  | "Quicksand"
  | "Quire Sans"
  | "Rajdhani"
  | "Raleway"
  | "Really No 2"
  | "Righteous"
  | "Roboto"
  | "Roboto Condensed"
  | "Roboto Mono"
  | "Roboto Slab"
  | "Rockwell"
  | "Rubik"
  | "Sabon Next"
  | "Sackers Square Gothic"
  | "Sagrantino"
  | "Scene"
  | "Scherzo"
  | "Shadows Into Light"
  | "Slate"
  | "Soho"
  | "Soho Gothic"
  | "Source Code Pro"
  | "Source Sans Pro"
  | "Stempel Schneidler"
  | "Swiss 721"
  | "Swiss 721 Rounded Bold"
  | "Tenor Sans"
  | "Tiemann"
  | "Times New Roman"
  | "Tinos"
  | "Titillium Web"
  | "Trade Gothic"
  | "Trade Gothic Next"
  | "Trebuchet MS"
  | "Twentieth Century"
  | "Ubuntu"
  | "Unica One"
  | "Univers Next"
  | "Univers Next Typewriter"
  | "Unna"
  | "Vala"
  | "Varela"
  | "Varela Round"
  | "Verdana Pro"
  | "Volkhov"
  | "Vollkorn"
  | "Waza"
  | "Wola"
  | "Work Sans"
  | "Ysobel"
  | "Zurich"
  | "Zurich Extended";

export const fontWeightOptions: { value: FontWeight; label: string }[] = [
  {
    value: "normal",
    label: "Normal",
  },
  {
    value: "bold",
    label: "Bold",
  },
  {
    value: "bolder",
    label: "Bolder",
  },
  {
    value: "lighter",
    label: "Lighter",
  },
];

export const fontFamilyOptions: { value: FontFamily; label: string }[] = [
  {
    value: "Mono",
    label: "Mono",
  },
  {
    value: "Sans-serif",
    label: "Sans-serif",
  },
  {
    value: "Serif",
    label: "Serif",
  },
  {
    value: "Abel",
    label: "Abel",
  },
  {
    value: "Abril Fatface",
    label: "Abril Fatface",
  },
  {
    value: "Agmena",
    label: "Agmena",
  },
  {
    value: "Akko",
    label: "Akko",
  },
  {
    value: "Alegreya",
    label: "Alegreya",
  },
  {
    value: "Alegreya Sans",
    label: "Alegreya Sans",
  },
  {
    value: "Americana",
    label: "Americana",
  },
  {
    value: "Amiri",
    label: "Amiri",
  },
  {
    value: "Anonymous Pro",
    label: "Anonymous Pro",
  },
  {
    value: "Antique Olive",
    label: "Antique Olive",
  },
  {
    value: "Arapey",
    label: "Arapey",
  },
  {
    value: "Archivo",
    label: "Archivo",
  },
  {
    value: "Archivo Narrow",
    label: "Archivo Narrow",
  },
  {
    value: "Arimo",
    label: "Arimo",
  },
  {
    value: "Arial",
    label: "Arial",
  },
  {
    value: "Armata",
    label: "Armata",
  },
  {
    value: "Arvo",
    label: "Arvo",
  },
  {
    value: "Asap",
    label: "Asap",
  },
  {
    value: "Assistant",
    label: "Assistant",
  },
  {
    value: "Asul",
    label: "Asul",
  },
  {
    value: "Avenir Next",
    label: "Avenir Next",
  },
  {
    value: "Avenir Next Rounded",
    label: "Avenir Next Rounded",
  },
  {
    value: "Azbuka",
    label: "Azbuka",
  },
  {
    value: "Basic Commercial",
    label: "Basic Commercial",
  },
  {
    value: "Basic Commercial Soft Rounded",
    label: "Basic Commercial Soft Rounded",
  },
  {
    value: "Baskerville No 2",
    label: "Baskerville No 2",
  },
  {
    value: "Bauer Bodoni",
    label: "Bauer Bodoni",
  },
  {
    value: "Beefcakes",
    label: "Beefcakes",
  },
  {
    value: "Bembo Book",
    label: "Bembo Book",
  },
  {
    value: "Bernhard Modern",
    label: "Bernhard Modern",
  },
  {
    value: "Bio Rhyme",
    label: "Bio Rhyme",
  },
  {
    value: "Bitter",
    label: "Bitter",
  },
  {
    value: "Bodoni Poster Black",
    label: "Bodoni Poster Black",
  },
  {
    value: "Burlingame",
    label: "Burlingame",
  },
  {
    value: "Cabin",
    label: "Cabin",
  },
  {
    value: "Cachet",
    label: "Cachet",
  },
  {
    value: "Cardamon",
    label: "Cardamon",
  },
  {
    value: "Cardo",
    label: "Cardo",
  },
  {
    value: "Carter Sans",
    label: "Carter Sans",
  },
  {
    value: "Caslon Bold",
    label: "Caslon Bold",
  },
  {
    value: "Caslon Old Face",
    label: "Caslon Old Face",
  },
  {
    value: "Catamaran",
    label: "Catamaran",
  },
  {
    value: "Centaur",
    label: "Centaur",
  },
  {
    value: "Century Gothic",
    label: "Century Gothic",
  },
  {
    value: "Chivo",
    label: "Chivo",
  },
  {
    value: "Chong Modern",
    label: "Chong Modern",
  },
  {
    value: "Claire News Light",
    label: "Claire News Light",
  },
  {
    value: "Cooper BT Medium",
    label: "Cooper BT Medium",
  },
  {
    value: "Courier New",
    label: "Courier New",
  },
  {
    value: "Crimson Text",
    label: "Crimson Text",
  },
  {
    value: "DIN Neuzeit Grotesk Light",
    label: "DIN Neuzeit Grotesk Light",
  },
  {
    value: "DIN Next",
    label: "DIN Next",
  },
  {
    value: "DIN Next Slab",
    label: "DIN Next Slab",
  },
  {
    value: "Daytona",
    label: "Daytona",
  },
  {
    value: "Domine",
    label: "Domine",
  },
  {
    value: "Dosis",
    label: "Dosis",
  },
  {
    value: "Electra",
    label: "Electra",
  },
  {
    value: "Eurostile Next",
    label: "Eurostile Next",
  },
  {
    value: "FF Meta",
    label: "FF Meta",
  },
  {
    value: "FF Meta Serif",
    label: "FF Meta Serif",
  },
  {
    value: "FF Tisa",
    label: "FF Tisa",
  },
  {
    value: "FF Tisa Sans",
    label: "FF Tisa Sans",
  },
  {
    value: "FF Unit",
    label: "FF Unit",
  },
  {
    value: "FF Unit Rounded",
    label: "FF Unit Rounded",
  },
  {
    value: "FF Unit Slab",
    label: "FF Unit Slab",
  },
  {
    value: "Fette Gotisch",
    label: "Fette Gotisch",
  },
  {
    value: "Fira Sans",
    label: "Fira Sans",
  },
  {
    value: "Fjalla One",
    label: "Fjalla One",
  },
  {
    value: "Friz Quadrata",
    label: "Friz Quadrata",
  },
  {
    value: "Frutiger Serif",
    label: "Frutiger Serif",
  },
  {
    value: "Futura",
    label: "Futura",
  },
  {
    value: "Futura Black",
    label: "Futura Black",
  },
  {
    value: "Garamond",
    label: "Garamond",
  },
  {
    value: "Geometric 415",
    label: "Geometric 415",
  },
  {
    value: "Georgia Pro",
    label: "Georgia Pro",
  },
  {
    value: "Gill Sans Nova",
    label: "Gill Sans Nova",
  },
  {
    value: "Glegoo",
    label: "Glegoo",
  },
  {
    value: "Goudy Old Style",
    label: "Goudy Old Style",
  },
  {
    value: "Harmonia Sans",
    label: "Harmonia Sans",
  },
  {
    value: "Helvetica",
    label: "Helvetica",
  },
  {
    value: "Humanist 521",
    label: "Humanist 521",
  },
  {
    value: "IBM Plex Sans",
    label: "IBM Plex Sans",
  },
  {
    value: "ITC Avant Garde Gothic",
    label: "ITC Avant Garde Gothic",
  },
  {
    value: "ITC Benguiat",
    label: "ITC Benguiat",
  },
  {
    value: "ITC Berkeley Old Style",
    label: "ITC Berkeley Old Style",
  },
  {
    value: "ITC Bodoni Seventytwo",
    label: "ITC Bodoni Seventytwo",
  },
  {
    value: "ITC Bodoni Twelve",
    label: "ITC Bodoni Twelve",
  },
  {
    value: "ITC Caslon No 224",
    label: "ITC Caslon No 224",
  },
  {
    value: "ITC Charter",
    label: "ITC Charter",
  },
  {
    value: "ITC Cheltenham",
    label: "ITC Cheltenham",
  },
  {
    value: "ITC Clearface",
    label: "ITC Clearface",
  },
  {
    value: "ITC Conduit",
    label: "ITC Conduit",
  },
  {
    value: "ITC Esprit",
    label: "ITC Esprit",
  },
  {
    value: "ITC Founders Caslon",
    label: "ITC Founders Caslon",
  },
  {
    value: "ITC Franklin Gothic",
    label: "ITC Franklin Gothic",
  },
  {
    value: "ITC Galliard",
    label: "ITC Galliard",
  },
  {
    value: "ITC Gamma",
    label: "ITC Gamma",
  },
  {
    value: "ITC Goudy Sans",
    label: "ITC Goudy Sans",
  },
  {
    value: "ITC Johnston Medium",
    label: "ITC Johnston Medium",
  },
  {
    value: "ITC Mendoza Roman",
    label: "ITC Mendoza Roman",
  },
  {
    value: "ITC Modern No 216 Medium",
    label: "ITC Modern No 216 Medium",
  },
  {
    value: "ITC New Baskerville",
    label: "ITC New Baskerville",
  },
  {
    value: "ITC New Esprit",
    label: "ITC New Esprit",
  },
  {
    value: "ITC New Veljovic",
    label: "ITC New Veljovic",
  },
  {
    value: "ITC Novarese",
    label: "ITC Novarese",
  },
  {
    value: "ITC Officina Sans",
    label: "ITC Officina Sans",
  },
  {
    value: "ITC Officina Serif",
    label: "ITC Officina Serif",
  },
  {
    value: "ITC Stepp",
    label: "ITC Stepp",
  },
  {
    value: "ITC Stone Humanist Medium",
    label: "ITC Stone Humanist Medium",
  },
  {
    value: "ITC Stone Informal",
    label: "ITC Stone Informal",
  },
  {
    value: "ITC Stone Serif",
    label: "ITC Stone Serif",
  },
  {
    value: "ITC Tapioca Medium",
    label: "ITC Tapioca Medium",
  },
  {
    value: "Inconsolata",
    label: "Inconsolata",
  },
  {
    value: "Joanna Nova",
    label: "Joanna Nova",
  },
  {
    value: "Joanna Sans Nova",
    label: "Joanna Sans Nova",
  },
  {
    value: "Josefin Sans",
    label: "Josefin Sans",
  },
  {
    value: "Josefin Slab",
    label: "Josefin Slab",
  },
  {
    value: "Kairos",
    label: "Kairos",
  },
  {
    value: "Kalam",
    label: "Kalam",
  },
  {
    value: "Karla",
    label: "Karla",
  },
  {
    value: "Kreon",
    label: "Kreon",
  },
  {
    value: "Lato",
    label: "Lato",
  },
  {
    value: "Laurentian",
    label: "Laurentian",
  },
  {
    value: "Libelle",
    label: "Libelle",
  },
  {
    value: "Libre Baskerville",
    label: "Libre Baskerville",
  },
  {
    value: "Libre Franklin",
    label: "Libre Franklin",
  },
  {
    value: "Linotype Didot",
    label: "Linotype Didot",
  },
  {
    value: "Linotype Gianotten",
    label: "Linotype Gianotten",
  },
  {
    value: "Linotype Really Medium",
    label: "Linotype Really Medium",
  },
  {
    value: "Linotype Syntax Serif",
    label: "Linotype Syntax Serif",
  },
  {
    value: "Lobster",
    label: "Lobster",
  },
  {
    value: "Lobster Two",
    label: "Lobster Two",
  },
  {
    value: "Lora",
    label: "Lora",
  },
  {
    value: "Lucia",
    label: "Lucia",
  },
  {
    value: "Lucida Grande",
    label: "Lucida Grande",
  },
  {
    value: "Luthersche Fraktur",
    label: "Luthersche Fraktur",
  },
  {
    value: "Madera",
    label: "Madera",
  },
  {
    value: "Malabar",
    label: "Malabar",
  },
  {
    value: "Mariposa Sans",
    label: "Mariposa Sans",
  },
  {
    value: "Maven Pro",
    label: "Maven Pro",
  },
  {
    value: "Megrim",
    label: "Megrim",
  },
  {
    value: "Melior",
    label: "Melior",
  },
  {
    value: "Memphis Medium",
    label: "Memphis Medium",
  },
  {
    value: "Memphis Soft Rounded Medium",
    label: "Memphis Soft Rounded Medium",
  },
  {
    value: "Mentor Sans",
    label: "Mentor Sans",
  },
  {
    value: "Merriweather Sans",
    label: "Merriweather Sans",
  },
  {
    value: "Metro Nova",
    label: "Metro Nova",
  },
  {
    value: "Modern No 20",
    label: "Modern No 20",
  },
  {
    value: "Monaco/Lucida Console",
    label: "Monaco/Lucida Console",
  },
  {
    value: "Monotype Baskerville",
    label: "Monotype Baskerville",
  },
  {
    value: "Monotype Bodoni",
    label: "Monotype Bodoni",
  },
  {
    value: "Monotype Century Old Style Medium",
    label: "Monotype Century Old Style Medium",
  },
  {
    value: "Monotype Goudy",
    label: "Monotype Goudy",
  },
  {
    value: "Monotype Goudy Modern",
    label: "Monotype Goudy Modern",
  },
  {
    value: "Monotype Italian Old Style",
    label: "Monotype Italian Old Style",
  },
  {
    value: "Monotype New Clarendon Medium",
    label: "Monotype New Clarendon Medium",
  },
  {
    value: "Monotype News Gothic",
    label: "Monotype News Gothic",
  },
  {
    value: "Monotype Sabon",
    label: "Monotype Sabon",
  },
  {
    value: "Montserrat",
    label: "Montserrat",
  },
  {
    value: "Mouse Memoirs",
    label: "Mouse Memoirs",
  },
  {
    value: "Neo Sans",
    label: "Neo Sans",
  },
  {
    value: "Neue Aachen",
    label: "Neue Aachen",
  },
  {
    value: "Neue Frutiger 1450",
    label: "Neue Frutiger 1450",
  },
  {
    value: "Neue Haas Unica",
    label: "Neue Haas Unica",
  },
  {
    value: "Neue Plak",
    label: "Neue Plak",
  },
  {
    value: "Neue Swift",
    label: "Neue Swift",
  },
  {
    value: "Neuton",
    label: "Neuton",
  },
  {
    value: "Neuzeit Office",
    label: "Neuzeit Office",
  },
  {
    value: "Neuzeit Office Soft Rounded",
    label: "Neuzeit Office Soft Rounded",
  },
  {
    value: "Neuzeit S",
    label: "Neuzeit S",
  },
  {
    value: "New Century Schoolbook",
    label: "New Century Schoolbook",
  },
  {
    value: "News 702",
    label: "News 702",
  },
  {
    value: "News 705",
    label: "News 705",
  },
  {
    value: "News Cycle",
    label: "News Cycle",
  },
  {
    value: "News Gothic No 2",
    label: "News Gothic No 2",
  },
  {
    value: "News Plantin",
    label: "News Plantin",
  },
  {
    value: "Nobile",
    label: "Nobile",
  },
  {
    value: "Noticia Text",
    label: "Noticia Text",
  },
  {
    value: "Noto Serif",
    label: "Noto Serif",
  },
  {
    value: "Nunito",
    label: "Nunito",
  },
  {
    value: "Nunito Sans",
    label: "Nunito Sans",
  },
  {
    value: "Old Standard TT",
    label: "Old Standard TT",
  },
  {
    value: "Open Sans",
    label: "Open Sans",
  },
  {
    value: "Open Sans Condensed Light",
    label: "Open Sans Condensed Light",
  },
  {
    value: "Optima nova",
    label: "Optima nova",
  },
  {
    value: "Ovo",
    label: "Ovo",
  },
  {
    value: "Oxygen",
    label: "Oxygen",
  },
  {
    value: "PMN Caecilia",
    label: "PMN Caecilia",
  },
  {
    value: "PT Mono",
    label: "PT Mono",
  },
  {
    value: "PT Sans",
    label: "PT Sans",
  },
  {
    value: "PT Sans Narrow",
    label: "PT Sans Narrow",
  },
  {
    value: "PT Serif",
    label: "PT Serif",
  },
  {
    value: "Pacifico",
    label: "Pacifico",
  },
  {
    value: "Palatino",
    label: "Palatino",
  },
  {
    value: "Parma",
    label: "Parma",
  },
  {
    value: "Perpetua",
    label: "Perpetua",
  },
  {
    value: "Plantin",
    label: "Plantin",
  },
  {
    value: "Playfair Display",
    label: "Playfair Display",
  },
  {
    value: "Poppins",
    label: "Poppins",
  },
  {
    value: "Prata",
    label: "Prata",
  },
  {
    value: "Prompt",
    label: "Prompt",
  },
  {
    value: "Quantico",
    label: "Quantico",
  },
  {
    value: "Quattrocento",
    label: "Quattrocento",
  },
  {
    value: "Quattrocento Sans",
    label: "Quattrocento Sans",
  },
  {
    value: "Questrial",
    label: "Questrial",
  },
  {
    value: "Quicksand",
    label: "Quicksand",
  },
  {
    value: "Quire Sans",
    label: "Quire Sans",
  },
  {
    value: "Rajdhani",
    label: "Rajdhani",
  },
  {
    value: "Raleway",
    label: "Raleway",
  },
  {
    value: "Really No 2",
    label: "Really No 2",
  },
  {
    value: "Righteous",
    label: "Righteous",
  },
  {
    value: "Roboto",
    label: "Roboto",
  },
  {
    value: "Roboto Condensed",
    label: "Roboto Condensed",
  },
  {
    value: "Roboto Mono",
    label: "Roboto Mono",
  },
  {
    value: "Roboto Slab",
    label: "Roboto Slab",
  },
  {
    value: "Rockwell",
    label: "Rockwell",
  },
  {
    value: "Rubik",
    label: "Rubik",
  },
  {
    value: "Sabon Next",
    label: "Sabon Next",
  },
  {
    value: "Sackers Square Gothic",
    label: "Sackers Square Gothic",
  },
  {
    value: "Sagrantino",
    label: "Sagrantino",
  },
  {
    value: "Scene",
    label: "Scene",
  },
  {
    value: "Scherzo",
    label: "Scherzo",
  },
  {
    value: "Shadows Into Light",
    label: "Shadows Into Light",
  },
  {
    value: "Slate",
    label: "Slate",
  },
  {
    value: "Soho",
    label: "Soho",
  },
  {
    value: "Soho Gothic",
    label: "Soho Gothic",
  },
  {
    value: "Source Code Pro",
    label: "Source Code Pro",
  },
  {
    value: "Source Sans Pro",
    label: "Source Sans Pro",
  },
  {
    value: "Stempel Schneidler",
    label: "Stempel Schneidler",
  },
  {
    value: "Swiss 721",
    label: "Swiss 721",
  },
  {
    value: "Swiss 721 Rounded Bold",
    label: "Swiss 721 Rounded Bold",
  },
  {
    value: "Tenor Sans",
    label: "Tenor Sans",
  },
  {
    value: "Tiemann",
    label: "Tiemann",
  },
  {
    value: "Times New Roman",
    label: "Times New Roman",
  },
  {
    value: "Tinos",
    label: "Tinos",
  },
  {
    value: "Titillium Web",
    label: "Titillium Web",
  },
  {
    value: "Trade Gothic",
    label: "Trade Gothic",
  },
  {
    value: "Trade Gothic Next",
    label: "Trade Gothic Next",
  },
  {
    value: "Trebuchet MS",
    label: "Trebuchet MS",
  },
  {
    value: "Twentieth Century",
    label: "Twentieth Century",
  },
  {
    value: "Ubuntu",
    label: "Ubuntu",
  },
  {
    value: "Unica One",
    label: "Unica One",
  },
  {
    value: "Univers Next",
    label: "Univers Next",
  },
  {
    value: "Univers Next Typewriter",
    label: "Univers Next Typewriter",
  },
  {
    value: "Unna",
    label: "Unna",
  },
  {
    value: "Vala",
    label: "Vala",
  },
  {
    value: "Varela",
    label: "Varela",
  },
  {
    value: "Varela Round",
    label: "Varela Round",
  },
  {
    value: "Verdana Pro",
    label: "Verdana Pro",
  },
  {
    value: "Volkhov",
    label: "Volkhov",
  },
  {
    value: "Vollkorn",
    label: "Vollkorn",
  },
  {
    value: "Waza",
    label: "Waza",
  },
  {
    value: "Wola",
    label: "Wola",
  },
  {
    value: "Work Sans",
    label: "Work Sans",
  },
  {
    value: "Ysobel",
    label: "Ysobel",
  },
  {
    value: "Zurich",
    label: "Zurich",
  },
  {
    value: "Zurich Extended",
    label: "Zurich Extended",
  },
];

export interface ICustomerPortalTheme {
  overrideCSS?: string;
  themeName?: string;

  // Modern Theme Values
  // Main Colors
  modernPageBackground?: string;
  modernCardBackground?: string;
  modernMenuBackground?: string;
  modernBadgeBackground?: string;
  modernIconsColor?: string;
  modernLineDividers?: string;

  //Borders
  modernCardRadius?: number;
  modernCardBorder?: number;
  modernCardBorderColor?: string;
  modernSmallCardBorderRadius?: number;
  modernSmallCardBorderWidth?: number;
  modernSmallCardBorderColor?: string;

  //Buttons
  modernPrimaryButtonColor?: string;
  modernPrimaryButtonBorderColor?: string;
  modernSecondaryButtonColor?: string;
  modernSecondaryButtonBorderColor?: string;
  modernButtonBorderWidth?: number;
  modernButtonBorderRadius?: number;
  modernButtonBorderHeight?: number;

  isPasswordless?: boolean;
  passwordlessStrategy?: PasswordlessStrategy;

  lastSavedThemeId?: string;

  modernThemeSettings?: IModernThemeSettings;
  translationOverrides?: ITranslationOverrides;

  //modern badges
  modernActiveBadgeColor?: string;
  modernPausedBadgeColor?: string;
  modernCancelledBadgeColor?: string;

  //banner
  modernBannerColor?: string;

  modernImageBorderRadius?: number;
  modernSmallImageBorderRadius?: number;

  modernOverrideCSS?: string;

  //Modern Fonts
  //Extra large
  modernExtraLargeFontFamily?: string;
  modernExtraLargeFontSize?: number;
  modernExtraLargeFontWeight?: string;
  modernExtraLargeFontLineHeight?: number;
  modernExtraLargeFontLetterSpacing?: number;
  modernExtraLargeFontColor?: string;

  // large
  modernLargeFontFamily?: string;
  modernLargeFontSize?: number;
  modernLargeFontWeight?: string;
  modernLargeFontLineHeight?: number;
  modernLargeFontLetterSpacing?: number;
  modernLargeFontColor?: string;

  //medium
  modernMediumFontFamily?: string;
  modernMediumFontSize?: number;
  modernMediumFontWeight?: string;
  modernMediumFontLineHeight?: number;
  modernMediumFontLetterSpacing?: number;
  modernMediumFontColor?: string;

  // small
  modernSmallFontFamily?: string;
  modernSmallFontSize?: number;
  modernSmallFontWeight?: string;
  modernSmallFontLineHeight?: number;
  modernSmallFontLetterSpacing?: number;
  modernSmallFontColor?: string;

  //subheading
  modernSubHeadingFontFamily?: string;
  modernSubHeadingFontSize?: number;
  modernSubHeadingFontWeight?: string;
  modernSubHeadingFontLineHeight?: number;
  modernSubHeadingFontLetterSpacing?: number;
  modernSubHeadingFontColor?: string;
  isSubHeadingUppercase?: boolean;

  //button CTA
  modernButtonCTAFontFamily?: string;
  modernButtonCTAFontSize?: number;
  modernButtonCTAFontWeight?: string;
  modernButtonCTAFontLineHeight?: number;
  modernButtonCTAFontLetterSpacing?: number;
  modernPrimaryButtonFontColor?: string;
  modernSecondaryButtonFontColor?: string;

  //body
  modernBodyFontFamily?: string;
  modernBodyFontSize?: number;
  modernBodyFontWeight?: string;
  modernBodyFontLineHeight?: number;
  modernBodyFontLetterSpacing?: number;
  modernBodyFontColor?: string;

  // caption
  modernCaptionFontFamily?: string;
  modernCaptionFontSize?: number;
  modernCaptionFontWeight?: string;
  modernCaptionFontLineHeight?: number;
  modernCaptionFontLetterSpacing?: number;
  modernCaptionFontColor?: string;
}

export type PasswordlessStrategy = "otp" | "magic-link";

export interface IModernThemeSettings {
  sections: {
    banner: {
      display: boolean;
      text: string;
      deleted: boolean;
    };
    subscriptions: {
      display: boolean;
      addOns: boolean;
      deleted: boolean;
      altView1: boolean;
    };
    settings: {
      display: boolean;
      deleted: boolean;
    };
    quickBuy: {
      display: boolean;
      deleted: boolean;
    };
    orderHistory: {
      display: boolean;
      deleted: boolean;
    };
    rewards: {
      display: boolean;
      deleted: boolean;
    };
    reviews: {
      display: boolean;
      deleted: boolean;
    };
    wishlist: {
      display: boolean;
      deleted: boolean;
    };
    instagram: {
      display: boolean;
    };
    trending: {
      display: boolean;
      heading: string;
      caption: string;
      showHeart: boolean;
      imgUrl: string;
      sortMethod: ValidSortMethods;
    };
  };
  sectionsOrder: SECTION[];
  welcomeInfo: {
    welcomeMessage: string;
    customerFirstName: boolean;
    customerEmail: boolean;
    customerSinceDate: boolean;
  };
  nextOrderActions: {
    sendNow: boolean;
    skip: boolean;
    gift: boolean;
    swap: boolean;
    delay: boolean;
    editAddress: boolean;
  };
  subscriptionActions: {
    editFrequency: boolean;
    setNextOrderDate: boolean;
    editSubscriptionAddress: boolean;
    editPayment: boolean;
    pause: boolean;
    /**
     * @deprecated use `showhide.showVariantName` instead
     * leaving this here for compatibility with old theme settings
     */
    showVariantName: boolean;
    subscriptionSwap: boolean;
  };
  showHide: {
    showVariantName: boolean;
    showPortalMenu: boolean;
    showUpcomingOrders: boolean;
    showDiscountCodeInput: boolean;
    showSectionTitles: boolean;
    showAddItemAsSub: boolean;
  };
  productScrollType: ProductScrollType;
  fontSizingType: ResponsiveFontStyling;
}

export enum AvailableLanguages {
  ENGLISH = "en",
  SPANISH = "es",
  FRENCH = "fr",
  PORTUGUESE = "pt",
}

export interface ITranslationOverrides {
  activeLanguage: AvailableLanguages;

  en: Record<string, IModifiedValuesProps>;
  fr: Record<string, IModifiedValuesProps>;
  es: Record<string, IModifiedValuesProps>;
  pt: Record<string, IModifiedValuesProps>;
}

export interface IModifiedValuesProps {
  /** Overwritten value is in use
   * @default false
   */
  enabled: boolean;

  value: string;
}

export enum SECTION {
  SUBSCRIPTION = "SUBSCRIPTION",
  LOYALTY = "LOYALTY",
  ORDERHISTORY = "ORDERHISTORY",
  SETTINGS = "SETTINGS",
  INSTAGRAM = "INSTAGRAM",
  TRENDING = "TRENDING",
}

export enum ThemeVersion {
  LEGACY = "legacy",
  MODERN = "modern",
}

export type ICustomerPortalThemeForm = RequireNilFields<ICustomerPortalTheme>;

export type ICustomerPortalVariableType =
  | "overrideCSS"
  | "themeName"
  | "isPasswordless"
  | "passwordlessStrategy"

  // Modern Theme Values
  // Main Colors
  | "modernPageBackground"
  | "modernCardBackground"
  | "modernMenuBackground"
  | "modernBadgeBackground"
  | "modernIconsColor"
  | "modernLineDividers"

  //Borders
  | "modernCardRadius"
  | "modernCardBorder"
  | "modernCardBorderColor"
  | "modernSmallCardBorderRadius"
  | "modernSmallCardBorderWidth"
  | "modernSmallCardBorderColor"

  //Buttons
  | "modernPrimaryButtonColor"
  | "modernPrimaryButtonBorderColor"
  | "modernSecondaryButtonColor"
  | "modernSecondaryButtonBorderColor"
  | "modernButtonBorderWidth"
  | "modernButtonBorderRadius"
  | "modernButtonBorderHeight"

  // modernThemeSettings IModernThemeSettings;

  //modern badges
  | "modernActiveBadgeColor"
  | "modernPausedBadgeColor"
  | "modernCancelledBadgeColor"

  //banner
  | "modernBannerColor"
  | "modernImageBorderRadius"
  | "modernSmallImageBorderRadius"
  | "modernOverrideCSS"

  //Modern Fonts
  //Extra large
  | "modernExtraLargeFontFamily"
  | "modernExtraLargeFontSize"
  | "modernExtraLargeFontWeight"
  | "modernExtraLargeFontLineHeight"
  | "modernExtraLargeFontLetterSpacing"
  | "modernExtraLargeFontColor"

  // large
  | "modernLargeFontFamily"
  | "modernLargeFontSize"
  | "modernLargeFontWeight"
  | "modernLargeFontLineHeight"
  | "modernLargeFontLetterSpacing"
  | "modernLargeFontColor"

  //medium
  | "modernMediumFontFamily"
  | "modernMediumFontSize"
  | "modernMediumFontWeight"
  | "modernMediumFontLineHeight"
  | "modernMediumFontLetterSpacing"
  | "modernMediumFontColor"

  // small
  | "modernSmallFontFamily"
  | "modernSmallFontSize"
  | "modernSmallFontWeight"
  | "modernSmallFontLineHeight"
  | "modernSmallFontLetterSpacing"
  | "modernSmallFontColor"

  //subheading
  | "modernSubHeadingFontFamily"
  | "modernSubHeadingFontSize"
  | "modernSubHeadingFontWeight"
  | "modernSubHeadingFontLineHeight"
  | "modernSubHeadingFontLetterSpacing"
  | "modernSubHeadingFontColor"

  //button CTA
  | "modernButtonCTAFontFamily"
  | "modernButtonCTAFontSize"
  | "modernButtonCTAFontWeight"
  | "modernButtonCTAFontLineHeight"
  | "modernButtonCTAFontLetterSpacing"
  | "modernPrimaryButtonFontColor"
  | "modernSecondaryButtonFontColor"

  //body
  | "modernBodyFontFamily"
  | "modernBodyFontSize"
  | "modernBodyFontWeight"
  | "modernBodyFontLineHeight"
  | "modernBodyFontLetterSpacing"
  | "modernBodyFontColor"

  // caption
  | "modernCaptionFontFamily"
  | "modernCaptionFontSize"
  | "modernCaptionFontWeight"
  | "modernCaptionFontLineHeight"
  | "modernCaptionFontLetterSpacing"
  | "modernCaptionFontColor";

export const customerPortalValidationSchema = yup.object().shape<ICustomerPortalThemeForm>({
  overrideCSS: yup.string(),
  themeName: yup.string(),

  // Modern Theme Values
  // Main Colors
  modernPageBackground: yup.string(),
  modernCardBackground: yup.string(),
  modernMenuBackground: yup.string(),
  modernBadgeBackground: yup.string(),
  modernIconsColor: yup.string(),
  modernLineDividers: yup.string(),

  //Borders
  modernCardRadius: yup.number(),
  modernCardBorder: yup.number(),
  modernCardBorderColor: yup.string(),
  modernSmallCardBorderRadius: yup.number(),
  modernSmallCardBorderWidth: yup.number(),
  modernSmallCardBorderColor: yup.string(),

  //Buttons
  modernPrimaryButtonColor: yup.string(),
  modernPrimaryButtonBorderColor: yup.string(),
  modernSecondaryButtonColor: yup.string(),
  modernSecondaryButtonBorderColor: yup.string(),
  modernButtonBorderWidth: yup.number(),
  modernButtonBorderRadius: yup.number(),
  modernButtonBorderHeight: yup.number(),

  isPasswordless: yup.string(),
  passwordlessStrategy: yup.string(),
  lastSavedThemeId: yup.string(),

  modernThemeSettings: yup.object(),
  translationOverrides: yup.object(),

  //modern badges
  modernActiveBadgeColor: yup.string(),
  modernPausedBadgeColor: yup.string(),
  modernCancelledBadgeColor: yup.string(),

  //banner
  modernBannerColor: yup.string(),

  modernImageBorderRadius: yup.number(),
  modernSmallImageBorderRadius: yup.number(),

  modernOverrideCSS: yup.string(),

  //Modern Fonts
  //Extra large
  modernExtraLargeFontFamily: yup.string(),
  modernExtraLargeFontSize: yup.number(),
  modernExtraLargeFontWeight: yup.string(),
  modernExtraLargeFontLineHeight: yup.number(),
  modernExtraLargeFontLetterSpacing: yup.number(),
  modernExtraLargeFontColor: yup.string(),

  // large
  modernLargeFontFamily: yup.string(),
  modernLargeFontSize: yup.number(),
  modernLargeFontWeight: yup.string(),
  modernLargeFontLineHeight: yup.number(),
  modernLargeFontLetterSpacing: yup.number(),
  modernLargeFontColor: yup.string(),

  //medium
  modernMediumFontFamily: yup.string(),
  modernMediumFontSize: yup.number(),
  modernMediumFontWeight: yup.string(),
  modernMediumFontLineHeight: yup.number(),
  modernMediumFontLetterSpacing: yup.number(),
  modernMediumFontColor: yup.string(),

  // small
  modernSmallFontFamily: yup.string(),
  modernSmallFontSize: yup.number(),
  modernSmallFontWeight: yup.string(),
  modernSmallFontLineHeight: yup.number(),
  modernSmallFontLetterSpacing: yup.number(),
  modernSmallFontColor: yup.string(),

  //subheading
  modernSubHeadingFontFamily: yup.string(),
  modernSubHeadingFontSize: yup.number(),
  modernSubHeadingFontWeight: yup.string(),
  modernSubHeadingFontLineHeight: yup.number(),
  modernSubHeadingFontLetterSpacing: yup.number(),
  modernSubHeadingFontColor: yup.string(),
  isSubHeadingUppercase: yup.boolean(),

  //button CTA
  modernButtonCTAFontFamily: yup.string(),
  modernButtonCTAFontSize: yup.number(),
  modernButtonCTAFontWeight: yup.string(),
  modernButtonCTAFontLineHeight: yup.number(),
  modernButtonCTAFontLetterSpacing: yup.number(),
  modernPrimaryButtonFontColor: yup.string(),
  modernSecondaryButtonFontColor: yup.string(),

  //body
  modernBodyFontFamily: yup.string(),
  modernBodyFontSize: yup.number(),
  modernBodyFontWeight: yup.string(),
  modernBodyFontLineHeight: yup.number(),
  modernBodyFontLetterSpacing: yup.number(),
  modernBodyFontColor: yup.string(),

  // caption
  modernCaptionFontFamily: yup.string(),
  modernCaptionFontSize: yup.number(),
  modernCaptionFontWeight: yup.string(),
  modernCaptionFontLineHeight: yup.number(),
  modernCaptionFontLetterSpacing: yup.number(),
  modernCaptionFontColor: yup.string(),
});
