import { getFromLocalStorage } from "./localStorageAccess";
import { VENDOR_API_HEADERS, jwtPropertyName } from "../constants";

export function getVendorBearerToken(): string | undefined {
  return getFromLocalStorage(jwtPropertyName);
}

interface TokenHeaderObj {
  [VENDOR_API_HEADERS.SMARTRR_AUTH_TOKEN]?: string;
}
export function makeBearerTokenHeaderObj(authToken: string | undefined): TokenHeaderObj {
  return authToken
    ? {
        [VENDOR_API_HEADERS.SMARTRR_AUTH_TOKEN]: `Bearer ${authToken}`,
      }
    : {};
}
