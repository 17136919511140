export const SentryDSN = {
  vendorPortal: "https://20aca0a2e3e849bc9fc85e5cdd2edecd@o4505190167674880.ingest.sentry.io/4505190227443712",
  customerPortal: "https://8e281f4677614486897a96ca52ea8018@o4505190167674880.ingest.sentry.io/4505285209161728",
  backend: "https://1d5a323f5567458a8575c3fdc5c38ab9@o4505190167674880.ingest.sentry.io/4505190204506112",
};

export const sampleRatesDefault = {
  // Performance Monitoring
  tracesSampleRate: 0.1,
  profilesSampleRate: 0.1,
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.1,
};

export const environment = process.env.NODE_ENV || "development";
export const release = process.env.RELEASE || "N/A";
