import { DateTime } from "luxon";

import { ISODateString } from "@smartrr/shared/entities/ISODateString";
import { IPurchasable } from "@smartrr/shared/entities/Purchasable";
import { IPurchasableVariant } from "@smartrr/shared/entities/PurchasableVariant";
import { IPurchaseStateLineItem } from "@smartrr/shared/entities/PurchaseState/CustomerPurchaseLineItem";

import { SmartrrSortType } from "./paginatedQuery";
import { LoyaltyApi } from "../interfaces/loyalty/api";
import { cloneDeep } from "lodash";

export enum ValidSortMethods {
  MANUAL = "manual",
  PRODUCT_TITLE_AZ = "productTitleAZ",
  NEWEST = "newest",
  OLDEST = "oldest",

  PRODUCT_PRICE_HIGHEST = "productPriceHighest",
  PRODUCT_PRICE_LOWEST = "productPriceLowest",
  BEST_SELLING = "bestSelling",
  PRODUCT_INVENTORY_HIGHEST = "productInventoryHighest",
  PRODUCT_INVENTORY_LOWEST = "productInventoryLowest",
}

export function smartrrSortBy<T, V>(
  arr: T[],
  accessor: (v: T) => V,
  firstIsMax: (a: V, b: V) => boolean,
  order: SmartrrSortType = "DESC"
) {
  return [...arr].sort((a, b) => {
    const firstIsMaxValue = firstIsMax(accessor(a), accessor(b));
    const aShouldGoFirst = order === "ASC" ? firstIsMaxValue : !firstIsMaxValue;
    return aShouldGoFirst ? 1 : -1;
  });
}

const firstIsMaxDate = (date1: Date, date2: Date) => +date1 >= +date2;

export function sortByDate<T>(arr: T[], accessor: (v: T) => Date | DateTime, order: SmartrrSortType = "DESC") {
  return smartrrSortBy(
    arr,
    v => {
      const dateOrDateTime = accessor(v);
      return dateOrDateTime instanceof DateTime ? dateOrDateTime.toJSDate() : dateOrDateTime;
    },
    firstIsMaxDate,
    order
  );
}

declare type sortByOptions = "high" | "low";

export function sortProductsListByMethod(
  productList: IPurchasable[],
  sortMethod?: ValidSortMethods
): IPurchasable[] {
  const sortedProductList = [...productList];

  if (!sortMethod) {
    return sortedProductList;
  }

  switch (sortMethod) {
    case ValidSortMethods.NEWEST: {
      return sortedProductList.sort((a, b) => byCreatedDateVariants(a, b, "high"));
    }
    case ValidSortMethods.OLDEST: {
      return sortedProductList.sort((a, b) => byCreatedDateVariants(a, b, "low"));
    }
    case ValidSortMethods.PRODUCT_TITLE_AZ: {
      return sortedProductList.sort((a, b) => a.purchasableName.localeCompare(b.purchasableName!)!);
    }

    default: {
      return sortedProductList;
    }
  }
}

export function byProductPrice(a: IPurchasableVariant, b: IPurchasableVariant, sortBy: sortByOptions): number {
  const p1 = a.presentmentPrices[0].price ?? 0;
  const p2 = b.presentmentPrices[0].price ?? 0;

  if (p1 !== p2) {
    if (sortBy === "high") {
      return p1 < p2 ? 1 : -1;
    }
    return p1 > p2 ? 1 : -1;
  }
  if (sortBy === "high") {
    return a.id < b.id ? 1 : -1;
  }
  return a.id > b.id ? 1 : -1;
}

export function byProductInventory(
  a: IPurchasableVariant,
  b: IPurchasableVariant,
  sortBy: sortByOptions
): number {
  const aInventory = a.inventoryQuantity ?? 0;
  const bInventory = b.inventoryQuantity ?? 0;
  if (aInventory !== bInventory) {
    if (sortBy === "high") {
      return aInventory < bInventory ? 1 : -1;
    }
    return aInventory > bInventory ? 1 : -1;
  }
  if (sortBy === "high") {
    return a.id < b.id ? 1 : -1;
  }
  return a.id > b.id ? 1 : -1;
}

export function byCreatedDateVariants(
  a: IPurchasableVariant | IPurchasable,
  b: IPurchasableVariant | IPurchasable,
  sortBy: sortByOptions
): number {
  const d1 = new Date(a.createdDate);
  const d2 = new Date(b.createdDate);
  if (d1 !== d2) {
    if (sortBy === "high") {
      return d1 < d2 ? 1 : -1;
    }
    return d1 > d2 ? 1 : -1;
  }
  if (sortBy === "high") {
    return a.id < b.id ? 1 : -1;
  }
  return a.id > b.id ? 1 : -1;
}

export function byCreatedDatesLineItems(a: IPurchaseStateLineItem, b: IPurchaseStateLineItem): number {
  const d1 = ISODateString.fromString(a.createdDate);
  const d2 = ISODateString.fromString(b.createdDate);
  if (d1 !== d2) {
    return d1 > d2 ? 1 : -1;
  } else if (a.priceAfterDiscounts !== b.priceAfterDiscounts) {
    return a.priceAfterDiscounts > b.priceAfterDiscounts ? 1 : -1;
  }
  // if created date and price equal, use id for stability
  return a.id > b.id ? 1 : -1;
}

export function byAddonStatus(a: IPurchaseStateLineItem, b: IPurchaseStateLineItem): number {
  return !a.isAddOn && b.isAddOn ? -1 : a.isAddOn && b.isAddOn ? 0 : a.isAddOn && !b.isAddOn ? 1 : -1;
}

export function sortByKey<T extends Record<string, unknown>>(arr: T[]): T[] {
  return arr.sort((a, b) => {
    const keyA = Object.keys(a)[0];
    const keyB = Object.keys(b)[0];
    return keyA.localeCompare(keyB);
  });
}

export function sortTiersByRank(tiers: LoyaltyApi.Tier.Type[]): LoyaltyApi.Tier.Type[] {
  if (!tiers.length) {
    return [];
  }
  const clonedTiers = cloneDeep(tiers);
  return clonedTiers.sort((a, b) => {
    if (a.tierRank > b.tierRank) {
      return 1;
    } else if (a.tierRank !== b.tierRank) {
      return -1;
    }
    // if for some reason the ranks are the same, we'll sort based on pointMin
    return a.minimumPoints > b.minimumPoints ? 1 : -1;
  });
}
