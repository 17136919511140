import {
  IAnalyticsSimpleQuery,
  IAnalyticsSimpleRes,
} from "@smartrr/shared/typedVendorApi/analyticsReqResInterfaces";
import { getLocaleDateRoundedToDay } from "@smartrr/shared/utils/dateRounding";
import moment from "moment";

import { SmartrrVendorSubReducer } from "@vendor-app/app/_state/typedVendorRedux";

export interface IAnalyticsReducerState {
  isLoading: boolean;

  simpleQuery: IAnalyticsSimpleQuery;
  analyticsSimpleResponse: IAnalyticsSimpleRes | null;
}

export const defaultStart = getLocaleDateRoundedToDay(moment().subtract(6, "month").toDate());
export const defaultEnd = getLocaleDateRoundedToDay(moment().toDate());

const initialState: IAnalyticsReducerState = {
  isLoading: true,

  simpleQuery: {
    startDate: defaultStart.toISOString(),
    endDate: defaultEnd.toISOString(),
  },
  analyticsSimpleResponse: null,
};

export const analyticsReducer: SmartrrVendorSubReducer<IAnalyticsReducerState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    // clear if vendor switches the organization they're looking at
    case "SELECT_ACTIVE_ORGANIZATION": {
      return initialState;
    }

    case "LOADING_ANALYTICS_SIMPLE": {
      return {
        ...state,
        isLoading: true,
        simpleQuery: action.payload,
      };
    }

    case "ERROR_LOADING_ANALYTICS_SIMPLE": {
      return {
        ...state,
        isLoading: false,
      };
    }

    case "LOADED_ANALYTICS_SIMPLE": {
      return {
        ...state,
        isLoading: false,
        analyticsSimpleResponse: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
