import { toUTCDate, toUTCDateLuxon } from "@smartrr/shared/utils/dateUtils";

export function getFirstOfMonthAndSubtractOneMonthAsLocaleDate(date: Date): Date {
  return toUTCDateLuxon(new Date(date.getFullYear(), date.getMonth(), 1)).minus({ months: 1 }).toJSDate();
  // return new Date(date.getFullYear(), date.getMonth(), 1);
}

export function getMondayAndSubtractOneWeekAsLocaleDate(date: Date): Date {
  return toUTCDateLuxon(date).minus({ weeks: 1 }).toJSDate();
  // return toUTCDateLuxon(date).minus({ days: diffToSunday }).plus({ days: 1 }).toJSDate();
}

export function getLocaleDateRoundedToDay(date: Date): Date {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

export function fromDateToUTCISORoundedToDay(toBeRounded: Date): string {
  const asISO = toBeRounded.toISOString();
  const year = Number.parseInt(asISO.slice(0, 4));
  const month = Number.parseInt(asISO.slice(5, 7));
  const dayOfMonth = Number.parseInt(asISO.slice(8, 10));
  return toUTCDate(new Date(year, month - 1, dayOfMonth)).toISOString();
}

export function holdISODayForNewDate(iso: string): Date {
  const year = Number.parseInt(iso.slice(0, 4));
  const month = Number.parseInt(iso.slice(5, 7));
  const dayOfMonth = Number.parseInt(iso.slice(8, 10));
  return toUTCDate(new Date(year, month - 1, dayOfMonth));
}

export function correctTimeZoneDateRolloverForView(interval: "month" | "week" | "day", dateIn: Date): Date {
  const typeCheckDate = new Date(dateIn);
  const dayAfter = toUTCDateLuxon(typeCheckDate).plus({ days: 1 }).toJSDate();
  const dayBefore = toUTCDateLuxon(typeCheckDate).minus({ days: 1 }).toJSDate();
  if (interval === "month") {
    if (typeCheckDate.getDate() === 1) {
      return typeCheckDate;
    }
    return dayAfter.getDate() === 1 ? dayAfter : dayBefore.getDate() === 1 ? dayBefore : typeCheckDate;
  }
  if (typeCheckDate.getDay() === 1) {
    return typeCheckDate;
  }
  return dayAfter.getDay() === 1 ? dayAfter : dayBefore.getDay() === 1 ? dayBefore : typeCheckDate;
}

export function correctDaylightRollover(desiredDay: Date, proposedDay: Date, interval: "week" | "month"): Date {
  const didWeMoveFromDaylightToStandard =
    interval === "week"
      ? desiredDay.getDay() !== proposedDay.getDay()
      : desiredDay.getDate() !== proposedDay.getDate();
  // this assumes that the only problematic daylight vs. standard rollover is when the selected period end is daylight
  // and the prev period is standard, in which case the
  return didWeMoveFromDaylightToStandard
    ? new Date(toUTCDateLuxon(proposedDay).plus({ days: 1 }).toJSDate())
    : new Date(proposedDay);
}

export function checkDaylight(truncatedISO: string): string {
  const convertedFromString = new Date(+new Date(truncatedISO)).getDate();
  const fromStringDirect = +truncatedISO.slice(8, 10);
  return convertedFromString === fromStringDirect
    ? truncatedISO
    : toUTCDateLuxon(new Date(truncatedISO)).plus({ days: 1 }).toJSDate().toISOString().slice(0, 10);
}
