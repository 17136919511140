import { IPauseReason } from "@smartrr/shared/entities/CancellationRelationship";

import { SmartrrVendorSubReducer } from "@vendor-app/app/_state/typedVendorRedux";

export interface IPauseReasonReducerState {
  isInitializing: boolean;
  isLoading: boolean;
  pauseReasons?: IPauseReason[];
  isUpdating: boolean;
}

const initialState: IPauseReasonReducerState = {
  isInitializing: true,
  isLoading: true,
  isUpdating: false,
};

export const pauseReasonReducer: SmartrrVendorSubReducer<IPauseReasonReducerState> = (
  state = initialState,
  action
): IPauseReasonReducerState => {
  switch (action.type) {
    case "LOADING_PAUSE_REASONS": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "ERROR_LOADING_PAUSE_REASONS": {
      return {
        ...state,
        isLoading: false,
      };
    }

    case "LOADED_PAUSE_REASONS": {
      return {
        ...state,
        isLoading: false,
        isInitializing: false,
        pauseReasons: action.payload.pauseReasons,
      };
    }

    default: {
      return state;
    }
  }
};
