import { IWebhook } from "@smartrr/shared/entities/Webhook";

import { SmartrrVendorSubReducer } from "@vendor-app/app/_state/typedVendorRedux";

export interface IWebhooksReducerState {
  isLoading: boolean;

  webhooks: IWebhook[];
}

const initialState: IWebhooksReducerState = {
  isLoading: true,
  webhooks: [],
};

export const webhooksReducer: SmartrrVendorSubReducer<IWebhooksReducerState> = (state = initialState, action) => {
  switch (action.type) {
    case "LOADING_WEBHOOKS": {
      return {
        ...state,
        webhooks: [],
        isLoading: true,
      };
    }

    case "ERROR_LOADING_WEBHOOKS": {
      return {
        ...state,
        isLoading: false,
      };
    }

    case "LOADED_WEBHOOKS": {
      return {
        ...state,
        isLoading: false,
        webhooks: action.payload.webhooks,
      };
    }

    default: {
      return state;
    }
  }
};
