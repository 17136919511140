import { IPurchasablesReducerState } from "@smartrr/shared/entities/Purchasable";
import { combineReducers } from "redux";

import { SmartrrAction, SmartrrActionActionType } from "@vendor-app/app/_state/typedVendorRedux";

// app

import { IAccountPlansReducerState, accountPlansReducer } from "./accountPlans";
import { IAnalyticsReducerState, analyticsReducer } from "./analytics";
import { IUserReducerState, userReducer } from "./auth";
import { IBillsReducerState, billsReducer } from "./bills";
import { ICancellationReasonReducerState, cancellationReasonReducer } from "./cancellationReasons";
import { ICustomerPortalThemeReducerState, customerPortalThemeReducer } from "./customerPortalTheme";
import { IPurchaseStatesReducerState, purchaseStatesReducer } from "./customerPurchaseState";
import { ICustomersReducerState, customerRelationsReducer } from "./customers";
import { IOrdersReducerState, ordersReducer } from "./orders";
import { IVendorOrganizationsReducerState, vendorOrganizationsReducer } from "./organizations";
import { IPauseReasonReducerState, pauseReasonReducer } from "./pauseReason";
import { IPhoneReducerState, phoneReducer } from "./phone";
import { IPurchasableCollectionsReducerState, purchasableCollectionsReducer } from "./purchasableCollections";
import { purchasablesReducer } from "./purchasables";
import { IShopifyStoreDataReducerState, shopifyStoreDataReducer } from "./shopify";
import { ISmsAddonReducerState, smsAddonReducer } from "./smsAddons";
import { IStripeConnectReducerState, stripeConnectReducer } from "./stripeConnect";
import { ISubscriptionDetailsState, subscriptionDetailsReducer } from "./subscriptionDetails";
import { ISuperUserReducerState, superUserReducer } from "./superUser";
import { IWebhooksReducerState, webhooksReducer } from "./webhooks";

const reducersObj = {
  auth: userReducer,
  accountPlans: accountPlansReducer,
  analytics: analyticsReducer,
  vendorOrganizations: vendorOrganizationsReducer,
  customerPortalTheme: customerPortalThemeReducer,
  purchasables: purchasablesReducer,
  purchasableCollections: purchasableCollectionsReducer,
  superUser: superUserReducer,
  shopifyStoreData: shopifyStoreDataReducer,
  stripeConnect: stripeConnectReducer,
  orders: ordersReducer,
  purchaseStates: purchaseStatesReducer,
  customerRelationships: customerRelationsReducer,
  bills: billsReducer,
  phone: phoneReducer,
  smsAddons: smsAddonReducer,
  webhooks: webhooksReducer,
  cancellationReasons: cancellationReasonReducer,
  pauseReasons: pauseReasonReducer,
  subscriptionDetails: subscriptionDetailsReducer,
};

// using object ref to ensure equality check failure
const impossibleToEqual = {};

// unsafe but should be harmless
// initialize with default values for each reducer
export const initialState: ISmartrrState = Object.keys(reducersObj).reduce((accum: any, key: string) => {
  // void 0 will trigger default value
  accum[key] = (reducersObj as any)[key](void 0, { type: impossibleToEqual });
  return accum;
}, {}) as any;

export const rootReducer = combineReducers<ISmartrrState, SmartrrAction<SmartrrActionActionType>>(reducersObj);

export interface ISmartrrState {
  auth: IUserReducerState;
  accountPlans: IAccountPlansReducerState;
  analytics: IAnalyticsReducerState;
  vendorOrganizations: IVendorOrganizationsReducerState;
  customerPortalTheme: ICustomerPortalThemeReducerState;
  purchasables: IPurchasablesReducerState;
  purchasableCollections: IPurchasableCollectionsReducerState;
  superUser: ISuperUserReducerState;
  shopifyStoreData: IShopifyStoreDataReducerState;
  stripeConnect: IStripeConnectReducerState;
  orders: IOrdersReducerState;
  purchaseStates: IPurchaseStatesReducerState;
  customerRelationships: ICustomersReducerState;
  bills: IBillsReducerState;
  phone: IPhoneReducerState;
  smsAddons: ISmsAddonReducerState;
  webhooks: IWebhooksReducerState;
  cancellationReasons: ICancellationReasonReducerState;
  pauseReasons: IPauseReasonReducerState;
  subscriptionDetails: ISubscriptionDetailsState;
}
